import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../Reducers/authSlice';
import registerReducer from '../Reducers/registerSlice';
import forgotPasswordReducer from '../Reducers/forgotPasswordSlice';
import teamReducer from '../Reducers/teamSlice';
import teamDataReducer from '../Reducers/teamDataSlice';
import teamActivityReducer from '../Reducers/teamActivitySlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        register: registerReducer,
        forgotPassword: forgotPasswordReducer,
        team: teamReducer,
        teamData: teamDataReducer,
        teamActivity: teamActivityReducer
    },
});

export default store;