import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { backUrl } from '../../constants';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import { ExportToCsv } from 'export-to-csv';
import './ViewData.css';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';

const ViewData = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const columns = [
        {
            accessorKey: 'name',
            header: "Name",
        },
        {
            accessorKey: "email",
            header: "Email",
        },
        {
            accessorKey: "website",
            header: "Website",
        },
        {
            accessorKey: "phone_number",
            header: "Phone Number",
        },
        {
            accessorKey: "address",
            header: "Address",
        },
        {
            accessorKey: "stage",
            header: "Stage",
        },
        {
            header: "Action", // Column header for the action buttons
            Cell: ({ row }) => (
                <Button style={{ backgroundColor: "#012970", color: "white" }} onClick={()=>navigate(`/dashboard/followup/${row.original._id}`)} >View</Button>
            ),
            size: 10,
        },
    ];

    const [originalData, setOriginalData] = useState([]);
    const [originalData2, setOriginalData2] = useState({});
    const [loading, setLoading] = useState(true);
    const selectedHeaders = ['Name', 'Email', 'Website', 'Phone Number', 'Address'];
    const selectedColumns = columns.filter(column => selectedHeaders.includes(column.header));

    const getAllData = async (id) => {
        try {
            const token = localStorage.getItem('token');

            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // Set the appropriate content type
            };

            const response = await axios.post(backUrl + 'data/getSingleData', { dataId: id }, { headers });
            setOriginalData(response.data.result);
            setOriginalData2(response.data.result2);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getAllData(id);
    }, []);

    const filteredUsers = originalData.map(asset => {
        const filteredData = {};
        selectedColumns.forEach(column => {
            filteredData[column.accessorKey] = asset[column.accessorKey];
        });
        return filteredData;
    });

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: selectedColumns.map((c) => c.header),
    };
    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {
        const filteredRows = rows.map(row => {
            const filteredData = {};
            selectedColumns.forEach(column => {
                filteredData[column.accessorKey] = row.original[column.accessorKey];
            });
            return filteredData;
        });

        csvExporter.generateCsv(filteredRows);
    };

    const handleExportData = () => {
        csvExporter.generateCsv(filteredUsers);
    };

    console.log(originalData);
    return (
        <>
            <div className='container-fluid'>
                <div className='row mt-2 goback_icon' onClick={()=> navigate(-1)}>
                    <MdOutlineArrowBackIosNew />
                </div>
                <div className='row mt-4'>
                    <h6 className='view_details_heading text-center'>Keyword: {originalData2.keyword}</h6>
                    <h6 className='view_details_heading text-center'>Place: {originalData2.place}</h6>
                </div>
                <div className='row'>
                    {loading ? (
                        <div className='row align-items-center justify-content-center'><div className='custom-loader col-6 align-self-center fw-bold mt-5'></div></div>
                    ) : (
                        <MaterialReactTable
                            columns={columns}
                            data={originalData}
                            enableRowSelection
                            positionToolbarAlertBanner="bottom"
                            renderTopToolbarCustomActions={({ table }) => (
                                <Box
                                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                >
                                    <Button
                                        style={{ backgroundColor: "#012970" }}
                                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                        onClick={handleExportData}
                                        variant="contained"
                                    >
                                        Export All Data
                                    </Button>

                                    <Button
                                        style={{
                                            backgroundColor: table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()
                                                ? "#012970"
                                                : "#CCCCCC",
                                        }}
                                        disabled={
                                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                        }
                                        //only export selected rows
                                        onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                        variant="contained"
                                    >
                                        Export Selected
                                    </Button>
                                </Box>
                            )}
                        />
                    )}
                </div>
            </div>
        </>
    )
}

export default ViewData