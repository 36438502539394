import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { backUrl } from "../constants";

const initialState = {
    currentTeam: null,
    teamData: null,
    loading: false,
    error: null,
};

export const getTeamData = createAsyncThunk(
    "team/getAllTeams",
    async (headers, { rejectWithValue }) => {
        try {
            if (headers) {
                const response = await axios.post(`${backUrl}team/fetchallteam`, {}, { headers });
                return response.data;
            } else {
                throw new Error('No token found');
            }
        } catch (error) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            } else {
                return rejectWithValue({ message: error.message });
            }
        }
    }
);

const teamSlice = createSlice({
    name: "team",
    initialState,
    reducers: {
        // Define any additional reducers if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTeamData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getTeamData.fulfilled, (state, action) => {
                state.loading = false;
                state.teamData = action.payload;
                state.error = null;
            })
            .addCase(getTeamData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export default teamSlice.reducer;
