import React, { useEffect, useState } from 'react';
import './GenerateLeads.css';
import axios from 'axios';
import { backUrl } from '../../constants';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import io from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTeamDetails } from '../../Reducers/teamDataSlice';

const socket = io.connect(backUrl);

const GenerateLeads = () => {
    const dispatch = useDispatch();

    const token = useSelector(state => state.auth.token);
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };

    const { teamData } = useSelector(state => state.team);
    const { team } = useSelector(state => state.teamData);

    const Swal = require('sweetalert2');

    const navigate = useNavigate();

    const [keyword, setKeyword] = useState("");
    const [place, setPlace] = useState("");
    const [keywordError, setKeywordError] = useState("");
    const [placeError, setPlaceError] = useState("");
    const [loading, setLoading] = useState(false);
    const [extractData, setExtractData] = useState('');
    const [scrappingData, setScrappingData] = useState('');

    const validateForm = () => {
        let isValid = true;

        // Reset error messages
        setKeywordError("");
        setPlaceError("");

        // Validate keyword
        if (keyword.trim() === "") {
            setKeywordError("Keyword is required.");
            isValid = false;
        }

        // Validate place
        if (place.trim() === "") {
            setPlaceError("Place is required.");
            isValid = false;
        }

        return isValid;
    };

    const submitHandler = async (e) => {

        if (validateForm()) {
            try {
                const token = localStorage.getItem('token');
                const teamDataFromLocalStorage = JSON.parse(localStorage.getItem('teamData'));

                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json', // Set the appropriate content type
                };

                if (team && team.length > 0) {
                    setLoading(true);
                    Swal.fire({
                        title: 'Done',
                        text: 'Lead generation can take some time. You can quit this page or, wait',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    });

                    console.log('teamId', team[0].teamdetails[0]._id);
                    const response = await axios.post(backUrl + 'data/getAllData', { name: keyword, place: place, linkedTeam: team[0].teamdetails[0]._id }, { headers });

                    console.log(response.data);

                    setKeyword("");
                    setPlace("");
                    navigate("/crm");
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Please create a team to submit',
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                }

            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        socket.on('extractingData', (updatedData) => {
            // Handle updated data here
            setExtractData(updatedData);
        });

        socket.on('scrappingData', (updatedData) => {
            // Handle updated data here
            setScrappingData(updatedData);
        });

    }, []);

    useEffect(() => {
        if (teamData) {
            dispatch(fetchTeamDetails({ teamid: teamData[0].teamid, headers }));
        }
    }, [teamData]);

    console.log('team', team);

    return (
        <>
            <div className='container-fluid'>
                <div className='row mt-5'>
                    <h3 className='text-center mt-4 generate_leads_heading'>GENERATE LEADS</h3>
                    <p className='text-center generate_leads_subheading'>We generate realtime data based on realtime information. So, generating leads take some time. Please wait after submiting.</p>
                </div>
                <div className='row'>
                    <div className='col-lg-6 input_div'>
                        <input type="text" className='keyword_input' placeholder='Enter Keyword' onChange={(e) => setKeyword(e.target.value)} value={keyword} />
                        <div className="text-danger">{keywordError}</div>
                    </div>
                    <div className='col-lg-6'>
                        <input type="text" className='place_input' placeholder='Enter Place' onChange={(e) => setPlace(e.target.value)} value={place} />
                        <div className="text-danger">{placeError}</div>
                    </div>
                </div>
                <div className='row justify-content-center mt-3'>
                    {loading ? (
                        <button className='generate_lead_button'>LOADING . . .</button>
                    ) : (
                        <button className='generate_lead_button' onClick={submitHandler}>SUBMIT</button>
                    )}
                </div>

                <div className='row py-5'>
                    <h5 className='extract_data'>{extractData}</h5>
                    <h5 className='scrap_data'>{scrappingData}</h5>
                </div>
            </div>
        </>
    )
}

export default GenerateLeads