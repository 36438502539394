import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { googleLogin } from '../../Reducers/authSlice';
import { useNavigate } from 'react-router-dom';

const GoogleCallback = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const extractTokenFromUrl = () => {
            const hash = window.location.hash;
            const params = new URLSearchParams(hash.substring(1));
            return params.get('access_token');
        };

        const token = extractTokenFromUrl();

        if (token) {
            dispatch(googleLogin(token));
            navigate('/');
        } else {
            navigate('/login');
        }
    }, [dispatch, navigate]);
  return (
    <div>Loading...</div>
  )
}

export default GoogleCallback