import React, { useEffect, useState } from 'react'
import './Sidebar.css';
import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ViewResults from '../CRM/ViewResults';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { backUrl } from '../../constants';
import ViewData from '../CRM/ViewData';
import GenerateLeads from '../CRM/GenerateLeads';
import Followup from '../CRM/Followup';
import Leads from '../Reports/Leads';

const token = localStorage.getItem('token');

const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json', // Set the appropriate content type
};

const Sidebar = () => {
    const [profileData, setProfileData] = useState("");
    const [teamData, setTeamData] = useState([]);
    const [currentTeam, setCurrentTeam] = useState({ teamname: "Team" });
    const [teamDataLoaded, setTeamDataLoaded] = useState(false);

    const getProfileData = () => {
        try {
            if (token) {
                const myData = jwt_decode(localStorage.getItem('token'))
                setProfileData(myData);
            }
        } catch (error) {
            console.log(error);
        };
    };

    const fetchallteam = async () => {
        try {
            if (token) {
                const response = await axios.post(backUrl + 'team/fetchallteam', {}, { headers });
                setTeamData(response.data);

                if (response.data.length > 0) {
                    localStorage.setItem('teamData', JSON.stringify(response.data[0]));
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getCurrentTeam = () => {
        try {
            const teamDataFromLocalStorage = localStorage.getItem('teamData');
            if (teamDataFromLocalStorage) {
                const parsedTeamData = JSON.parse(teamDataFromLocalStorage);
                setCurrentTeam(parsedTeamData);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const logoutHandler = () => {
        localStorage.clear();
        getProfileData();
        fetchallteam();

        // Redirect and reload the page
        window.location.href = '/';
    }

    useEffect(() => {
        getProfileData();
        fetchallteam();
    }, []);

    useEffect(() => {
        if (teamData.length > 0 && !teamDataLoaded) {
            getCurrentTeam();
            setTeamDataLoaded(true);
        }
    }, [teamData, teamDataLoaded]);

    return (
        <>
            <header id="header" class="header fixed-top d-flex align-items-center custom_header_navbar">

                <div class="d-flex align-items-center justify-content-between">
                    <a href="index.html" class="logo d-flex align-items-center link_delink">
                        {/* <img src="assets/img/logo.png" alt="" /> */}
                        <span class="brand_name_style">Scrapodile</span>
                    </a>
                    <i class="bi bi-list toggle-sidebar-btn d-block d-xl-none"></i>
                </div>

                <nav class="header-nav ms-auto">
                    <ul class="d-flex align-items-center">

                        <li class="nav-item dropdown pe-3">

                            <Link class="nav-link nav-profile d-flex align-items-center pe-0" to="/" data-bs-toggle="dropdown">
                                <img src={profileData.profilePhoto} alt="Profile" class="rounded-circle profile_image_header" />
                                <span class="d-none d-md-block dropdown-toggle ps-2">{profileData.name}</span>
                            </Link>

                            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile profile_dropdown">
                                <li class="dropdown-header">
                                    <h6 className='profile_name'>{profileData.name}</h6>
                                    {/* <span>Web Designer</span> */}
                                </li>
                                <li>
                                    <hr class="dropdown-divider" />
                                </li>

                                <li>
                                    <Link class="dropdown-item d-flex align-items-center profile_name" to="/profile">
                                        <i class="bi bi-person"></i>
                                        <span>My Profile</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr class="dropdown-divider" />
                                </li>

                                <li>
                                    <Link class="dropdown-item d-flex align-items-center profile_name" to="/account-setting">
                                        <i class="bi bi-gear"></i>
                                        <span>Account Settings</span>
                                    </Link>
                                </li>
                                <li>
                                    <hr class="dropdown-divider" />
                                </li>
                                {/* 
                                <li>
                                    <a class="dropdown-item d-flex align-items-center" href="pages-faq.html">
                                        <i class="bi bi-question-circle"></i>
                                        <span>Need Help?</span>
                                    </a>
                                </li>
                                <li>
                                    <hr class="dropdown-divider" />
                                </li> */}

                                <li>
                                    <Link class="dropdown-item d-flex align-items-center profile_name" onClick={logoutHandler}>
                                        <i class="bi bi-box-arrow-right"></i>
                                        <span>Sign Out</span>
                                    </Link>
                                </li>

                            </ul>
                        </li>

                    </ul>
                </nav>

            </header>

            <aside id="sidebar" class="sidebar custom_sidebar">

                <ul class="sidebar-nav" id="sidebar-nav">

                    <li class="nav-item">
                        <Link class="nav-link " to="/">
                            <i class="bi bi-grid"></i>
                            <span>Dashboard</span>
                        </Link>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link collapsed custom_li_item" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
                            <i class="bi bi-menu-button-wide"></i><span>CRM</span><i class="bi bi-chevron-down ms-auto"></i>
                        </a>
                        <ul id="components-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
                            <li className='sub_link_menu'>
                                <Link className="sidebar_sublink" to="crm">
                                    <span>All Leads</span>
                                </Link>
                            </li>
                            <li className='sub_link_menu'>
                                <Link className="sidebar_sublink" to="generate-leads">
                                    <span>Generate Leads</span>
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link collapsed" data-bs-target="#components-nav2" data-bs-toggle="collapse" href="#">
                            <i class="bi bi-file-earmark-text"></i><span>Reports</span><i class="bi bi-chevron-down ms-auto"></i>
                        </a>
                        <ul id="components-nav2" class="nav-content collapse " data-bs-parent="#sidebar-nav2">
                            <li className='sub_link_menu'>
                                <Link className="sidebar_sublink" to="leads">
                                    <span>Leads</span>
                                </Link>
                            </li>
                            <li className='sub_link_menu'>
                                <Link className="sidebar_sublink" to="mails">
                                    <span>Mails</span>
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <Link class="nav-link collapsed" to="team">
                            <i class="bi bi-people custom_icon_sidebar"></i>
                            <span>Team</span>
                        </Link>
                    </li>

                </ul>

            </aside>

            <main id="main" class="main custom_main">
                <Routes>
                    <Route exact path="/crm" element={<ViewResults />} />
                    <Route exact path="/viewdata/:id" element={<ViewData />} />
                    <Route exact path="/generate-leads" element={<GenerateLeads />} />
                    <Route exact path="/followup/:id" element={<Followup />} />
                    <Route exact path="/leads" element={<Leads />} />
                </Routes>


            </main>
        </>
    )
}

export default Sidebar