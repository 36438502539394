import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TiThMenu } from "react-icons/ti";
import jwt_decode from 'jwt-decode';
import { useSelector } from 'react-redux';


const Navbar = () => {
    const token = useSelector((state) => state.auth.token);

    const [profileData, setProfileData] = useState("");

    const getProfileData = () => {
        try {
            const myData = jwt_decode(token)
            setProfileData(myData);
        } catch (error) {
            console.log(error);
        };
    };

    useEffect(() => {
        getProfileData();
    }, []);

    return (
        <>
            <div className='container-fluid fixed-top bg-white navbar_container'>
                <div className='row py-2 full_navbar'>
                    <div className='col-3 left_header'>
                        <div>
                            Scrapodile
                        </div>
                    </div>
                    <div className='col-9 right_header'>
                        <a className='nav_elements' href="#home">Home</a>
                        <a className='nav_elements' href="#services">Services</a>
                        <a className='nav_elements' href="#pricing">Pricing</a>
                        <a className='nav_elements' href="#contact">Contact</a>
                        <a className='nav_elements' href="#about">About</a>
                        {token ? (<Link className='nav_elements' to="/dashboard">Dashboard</Link>) : (<Link className='nav_elements' to="/login">Login</Link>)}
                        {token ? (
                            <div className='profile_tab_container'>
                                <img src="/Images/profiledefault.png" className='profile_navbar' />
                                <span className='name_navbar ps-1'>{profileData.name}</span>
                            </div>
                        ) : (
                            <div className='profile_tab_container'>
                                <button className='start_free_trial_button'>Start a free trial</button>
                            </div>
                        )}
                    </div>

                    {/* Menu Button */}
                    <div className='col-9 menu_button_div' type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                        <TiThMenu />
                    </div>

                    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                        <div class="offcanvas-header d-flex justify-content-end">
                            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body mobile_navigation_body">
                            <div className='mb-4' data-bs-dismiss="offcanvas" aria-label="Close">
                                <a className='nav_elements' href="#home">Home</a>
                            </div>
                            <div className='mb-4' data-bs-dismiss="offcanvas" aria-label="Close">
                                <a className='nav_elements' href="#services">Services</a>
                            </div>
                            <div className='mb-4' data-bs-dismiss="offcanvas" aria-label="Close">
                                <a className='nav_elements' href="#pricing">Pricing</a>
                            </div>
                            <div className='mb-4' data-bs-dismiss="offcanvas" aria-label="Close">
                                <a className='nav_elements' href="#contact">Contact</a>
                            </div>
                            <div className='mb-4' data-bs-dismiss="offcanvas" aria-label="Close">
                                <a className='nav_elements' href="#about">About</a>
                            </div>
                            <div className='mb-4' data-bs-dismiss="offcanvas" aria-label="Close">
                                {token ? (<Link className='nav_elements' to="/dashboard">Dashboard</Link>) : (<Link className='nav_elements' to="/login">Login</Link>)}
                            </div>
                            <div className='mb-4' data-bs-dismiss="offcanvas" aria-label="Close">
                            {token ? (
                            <div className='profile_tab_container'>
                                <img src="/Images/profiledefault.png" className='profile_navbar' />
                                <span className='name_navbar ps-1'>Sanjeet</span>
                            </div>
                        ) : (
                            <div className='profile_tab_container'>
                                <button className='start_free_trial_button'>Start a free trial</button>
                            </div>
                        )}
                            </div>
                        </div>
                    </div>

                </div>
            </div>|
        </>
    )
}

export default Navbar