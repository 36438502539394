import React from 'react';
import { IoMdMail } from "react-icons/io";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { FaPhoneVolume } from "react-icons/fa6";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";

const Contact = () => {
    return (
        <div className='container-fluid service_container py-5' id='contact'>
            <div className='row contact_row'>
                <div className='col-lg-5'>
                    <div className='row'>
                        <h5 className='contact_name_heading pb-3 pt-5'>Conatct Us</h5>
                        <h3 className='contact_ready'>Ready to get started?</h3>
                        <h3 className='contact_ready pb-4'>Let's chat</h3>
                    </div>
                    <div className='row info_section_div text-white'>
                        <div className='info_section'>
                            <IoMdMail />
                            <h6 className='ps-2'>info@ajatus.co.in</h6>
                        </div>
                        <div className='info_section'>
                            <HiOutlineLocationMarker />
                            <h6 className='ps-2'>South Block,New OCAC Towers Acharya Vihar, Bhubaneswar</h6>
                        </div>
                        <div className='info_section'>
                            <FaPhoneVolume />
                            <h6 className='ps-2'>+91- 9090890909</h6>
                        </div>
                        <div className='social_section py-5'>
                            <span className='social_icons'><FaFacebookF /></span>
                            <span className='social_icons'><FaLinkedinIn /></span>
                            <span className='social_icons'><FaTwitter /></span>
                            <span className='social_icons'><FaInstagram /></span>
                        </div>
                    </div>
                </div>

                <div className='col-lg-5 contact_div'>
                    <div className='row'>
                        <div className='col-lg-6 px-1'>
                            <input className='input_contact_form' placeholder='Full Name' />
                        </div>
                        <div className='col-lg-6 px-1'>
                            <input className='input_contact_form' placeholder='Email Address' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6 px-1'>
                            <input className='input_contact_form' placeholder='Phone Number' />
                        </div>
                        <div className='col-lg-6 px-1'>
                            <input className='input_contact_form' placeholder='Website' />
                        </div>
                    </div>
                    <div className='row px-1'>
                        <textarea className='input_contact_form_large' placeholder='Message' />
                    </div>
                    <div className='row px-1'>
                        <button className='send_message_button_contact'>Send Message</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact