import React from 'react';
import './ReceivedMail.css';

const ReceivedMail = () => {
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <h5 className='text-center my-3 all_results_heading'>RECEIVED MAIL</h5>
                </div>

                <div class="row header-row align-items-center">
                    <div class="col-1">
                        <input type="checkbox" class="tick-all" />
                    </div>
                    <div class="col-1">
                        <span class="reload">&#x21bb;</span>
                    </div>
                    <div class="col-8">
                    </div>
                    <div class="col-2 pagination-info">
                        <span>1-50 of 234</span>
                        <span class="arrow">&#8592;</span>
                        <span class="arrow">&#8594;</span>
                    </div>
                </div>

                <div className='row'>
                    <div class="col-12">
                        <div class="mail-item row align-items-center">
                            <div class="col-1">
                                <input type="checkbox" class="tick" />
                            </div>
                            <div class="col-1">
                                <span class="star">&#9734;</span>
                            </div>
                            <div class="col-4 subject">
                                Email Subject 1
                            </div>
                            <div class="col-4 content">
                                Email content snippet goes here...
                            </div>
                            <div class="col-2 time">
                                10:30 AM
                            </div>
                        </div>

                        <div class="mail-item row align-items-center">
                            <div class="col-1">
                                <input type="checkbox" class="tick" />
                            </div>
                            <div class="col-1">
                                <span class="star">&#9734;</span>
                            </div>
                            <div class="col-4 subject">
                                Email Subject 2
                            </div>
                            <div class="col-4 content">
                                Email content snippet goes here...
                            </div>
                            <div class="col-2 time">
                                9:15 AM
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ReceivedMail