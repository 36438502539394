export const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };

    const day = date.getDate();
    let suffix = 'th';

    if (day === 1 || day === 21 || day === 31) {
        suffix = 'st';
    } else if (day === 2 || day === 22) {
        suffix = 'nd';
    } else if (day === 3 || day === 23) {
        suffix = 'rd';
    }

    const formattedDate = date.toLocaleDateString('en-US', options);
    const month = formattedDate.slice(0, formattedDate.indexOf(' '));
    const year = formattedDate.slice(formattedDate.lastIndexOf(' ') + 1);

    return `${day}${suffix} ${month} ${year}`;
};

export const convertISOToCustomFormat = (inputDate) => {
    const date = new Date(inputDate);

    const dateFormat = new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
    const formattedDate = dateFormat.format(date);

    const timeFormat = new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
    const formattedTime = timeFormat.format(date);

    const formattedDateTime = `${formattedDate} ${formattedTime}`;

    return formattedDateTime;
}

export const formatDateToDDMMYYYYHHMMAM = (dateString) => {
    console.log(dateString);
    const date = new Date(dateString);
    const options = { hour: '2-digit', minute: '2-digit', hour12: true };

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
    const year = date.getFullYear();

    // Get the time in the desired format
    const time = new Intl.DateTimeFormat('en-US', options).format(date);
    
    // Combine the parts into the desired format
    return `${day}-${month}-${year} ${time}`;
}