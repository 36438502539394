import React, { useEffect, useState } from 'react';
import "./Leads.css";
import axios from 'axios';
import { backUrl } from '../../constants';
import { MaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import { ExportToCsv } from 'export-to-csv';

const token = localStorage.getItem('token');

const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json', // Set the appropriate content type
};

const Leads = () => {
    const columns = [
        {
            accessorKey: 'name',
            header: "Name",
        },
        {
            accessorKey: "email",
            header: "Email",
        },
        {
            accessorKey: "website",
            header: "Website",
        },
        {
            accessorKey: "phone_number",
            header: "Phone Number",
        },
        {
            accessorKey: "stage",
            header: "Stage",
        },
        {
            accessorKey: "updatedAtIST",
            header: "Last Update",
            size: 200,
        },
    ];

    const [resultData, setResultData] = useState([]);
    const [inputKeyword, setInputKeyword] = useState('');
    const [inputPLace, setInputPLace] = useState('');
    const [afterSubmitKeyword, setAfterSubmitKeyword] = useState('');
    const [afterSubmitPlace, setAfterSubmitPlace] = useState('');
    const [loading, setLoading] = useState(true);

    const selectedHeaders = ['Name', 'Email', 'Website', 'Phone Number', 'Stage', 'Last Update'];
    const selectedColumns = columns.filter(column => selectedHeaders.includes(column.header));

    const getResultWithFilter = async () => {
        try {
            const body = {
                keyword: inputKeyword.trim(),
                place: inputPLace.trim(),
            };

            // Remove empty properties from the body object
            Object.keys(body).forEach(key => body[key] === '' && delete body[key]);

            const response = await axios.post(backUrl + "data/getResultWithFilter", body, { headers });
            setResultData(response.data);
            setAfterSubmitKeyword(inputKeyword);
            setAfterSubmitPlace(inputPLace);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const filteredUsers = resultData.map(asset => {
        const filteredData = {};
        selectedColumns.forEach(column => {
            filteredData[column.accessorKey] = asset[column.accessorKey];
        });
        return filteredData;
    });

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: selectedColumns.map((c) => c.header),
    };
    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {
        const filteredRows = rows.map(row => {
            const filteredData = {};
            selectedColumns.forEach(column => {
                filteredData[column.accessorKey] = row.original[column.accessorKey];
            });
            return filteredData;
        });

        csvExporter.generateCsv(filteredRows);
    };


    const handleExportData = () => {
        csvExporter.generateCsv(filteredUsers);
    };

    useEffect(() => {
        // getResultWithFilter();
    }, []);

    return (
        <>
            <div className='container-fluid'>
                <div className='row mt-3'>
                    <h5 className='report_heading'>LEADS REPORT</h5>
                </div>
                <div className='row mt-2'>
                    <div className='col-lg-3'>
                        <h5 className='input_label_report'>Keyword</h5>
                        <input type="text" className='report_input' onChange={(e) => setInputKeyword(e.target.value)} value={inputKeyword} placeholder='Ex. Resturant' />
                    </div>
                    <div className='col-lg-3'>
                        <h5 className='input_label_report'>Place</h5>
                        <input type="text" className='report_input' placeholder='Ex. Europe' onChange={(e) => setInputPLace(e.target.value)} value={inputPLace} />
                    </div>
                    <div className='col-lg-3 d-flex'>
                        <button className='get_report_button' onClick={getResultWithFilter}>Get Report</button>
                    </div>
                </div>
                <div className='row mt-2'>
                    <h6 className='show_result_heading mt-2'>{afterSubmitKeyword !== "" || afterSubmitPlace !== "" && "Showing result for"} <span className='showing_result'>{afterSubmitKeyword !== "" && "Keyword: " + afterSubmitKeyword} {afterSubmitPlace !== "" && "Place: " + afterSubmitPlace}</span> </h6>

                    <div className='row'>
                        {loading ? (
                            <div className='row align-items-center justify-content-center'><div className='custom-loader col-6 align-self-center fw-bold mt-5'></div></div>
                        ) : (
                            <MaterialReactTable
                                columns={columns}
                                data={resultData}
                                enableRowSelection
                                positionToolbarAlertBanner="bottom"
                                renderTopToolbarCustomActions={({ table }) => (
                                    <Box
                                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                    >
                                        <Button
                                            style={{ backgroundColor: "#012970" }}
                                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                            onClick={handleExportData}
                                            variant="contained"
                                        >
                                            Export All Data
                                        </Button>

                                        <Button
                                            style={{
                                                backgroundColor: table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()
                                                    ? "#012970"
                                                    : "#CCCCCC",
                                            }}
                                            disabled={
                                                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                            }
                                            //only export selected rows
                                            onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                            variant="contained"
                                        >
                                            Export Selected
                                        </Button>
                                    </Box>
                                )}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Leads