import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { backUrl } from "../constants";
import { useNavigate } from 'react-router-dom';

const loadTokenFromLocalStorage = () => {
    try {
        const token = localStorage.getItem("token");
        if (token) {
            return token;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error loading token from localStorage:", error);
        return null;
    }
};

const initialState = {
    isRegistering: false,
    registerError: null,
    isAuthenticated: false,
    token: loadTokenFromLocalStorage(),
    registrationData: {
        name: "",
        email: "",
    },
    showRegisterPage: true,
    showOtp: false,
    otpReference: null,
    showPasswordPage: false,
};

// Async thunk for registration (Stage 1)
export const registerUser = createAsyncThunk(
    'register/registerUser',
    async ({ name, email }, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${backUrl}auth/sendotp`, { name, email });
            return response.data; // Assuming response contains { otpReference }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Async thunk for OTP verification (Stage 2)
export const verifyOtp = createAsyncThunk(
    'register/verifyOtp',
    async ({ otpReference, otp }, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${backUrl}auth/verify-otp`, { otpReference, otp });
            return response.data; // Assuming response indicates success
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Async thunk for setting password (Stage 3)
export const setPassword = createAsyncThunk(
    'register/setPassword',
    async ({ name, email, password }, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${backUrl}auth/set-password`, { name, email, password });
            
            // Store token in localStorage
            if (response) {
                localStorage.setItem('token', response.data);
            }

            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        resetRegistrationState: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        // Handle registerUser (Stage 1)
        builder
            .addCase(registerUser.pending, (state) => {
                state.isRegistering = true;
                state.registerError = null;
                state.isAuthenticated = false;
                state.token = null;
                state.showOtp = false;
                state.showPasswordPage = false;
                state.showRegisterPage = true;
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.isRegistering = false;
                state.registrationData = action.meta.arg;
                state.showOtp = true;
                state.otpReference = action.payload.otpReference;
                state.showRegisterPage = false;
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.isRegistering = false;
                state.registerError = action.payload;
            });

        // Handle verifyOtp (Stage 2)
        builder
            .addCase(verifyOtp.pending, (state) => {
                state.isRegistering = true;
                state.registerError = null;
            })
            .addCase(verifyOtp.fulfilled, (state) => {
                state.isRegistering = false;
                state.showOtp = false;
                state.showPasswordPage = true;
            })
            .addCase(verifyOtp.rejected, (state, action) => {
                state.isRegistering = false;
                state.registerError = action.payload;
            });

        // Handle setPassword (Stage 3)
        builder
            .addCase(setPassword.pending, (state) => {
                state.isRegistering = true;
                state.registerError = null;
            })
            .addCase(setPassword.fulfilled, (state, action) => {
                state.isRegistering = false;
                state.isAuthenticated = true;
                state.token = action.payload;
                state.showPasswordPage = false;
                state.showRegisterPage = false;
                try {
                    localStorage.setItem("token", action.payload);
                } catch (error) {
                    console.error("Error saving token to localStorage:", error);
                }
            })
            .addCase(setPassword.rejected, (state, action) => {
                state.isRegistering = false;
                state.registerError = action.payload;
            });
    },
});

export const { resetRegistrationState } = registerSlice.actions;
export default registerSlice.reducer;
