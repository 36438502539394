import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { backUrl } from "../constants";

// Helper function to load token from localStorage
const loadTokenFromLocalStorage = () => {
    try {
        const token = localStorage.getItem("token");
        if (token) {
            return token;
        } else {
            return null;
        }
    } catch (error) {
        console.error("Error loading token from localStorage:", error);
        return null;
    }
};

// Initial state
const initialState = {
    isAuthenticated: false,
    token: loadTokenFromLocalStorage(),
    user: null,
    loading: false,
    error: null,
};

// Async thunk for user login
export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async (credentials, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${backUrl}auth/login`, credentials);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Async thunk for Google login
export const googleLogin = createAsyncThunk(
    'auth/googleLogin',
    async (tokenId, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${backUrl}auth/google-login`, { tokenId });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Slice
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.isAuthenticated = false;
            state.token = null;
            state.user = null;
            state.error = null;
            try {
                localStorage.removeItem("token");
            } catch (error) {
                console.error("Error removing token from localStorage:", error);
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.isAuthenticated = true;
                state.token = action.payload;
                state.loading = false;
                try {
                    localStorage.setItem("token", action.payload);
                } catch (error) {
                    console.error("Error saving token to localStorage:", error);
                }
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Something went wrong';
            })
            .addCase(googleLogin.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(googleLogin.fulfilled, (state, action) => {
                state.isAuthenticated = true;
                state.token = action.payload;
                state.loading = false;
                try {
                    localStorage.setItem("token", action.payload);
                } catch (error) {
                    console.error("Error saving token to localStorage:", error);
                }
            })
            .addCase(googleLogin.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Something went wrong';
            });
    },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
