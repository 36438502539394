import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { backUrl } from '../constants';

export const fetchTeamActivity = createAsyncThunk('activity/fetchTeamActivity', async (teamid) => {
    const headers = teamid.headers;
    const response = await axios.post(`${backUrl}activity/fetchteamactivity`, { teamid }, { headers });
    return response.data;
});

const activitySlice = createSlice({
    name: 'teamActivity',
    initialState: {
        activity: [],
        status: 'idle',
        error: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTeamActivity.fulfilled, (state, action) => {
                state.activity = action.payload;
            })
            .addDefaultCase((state, action) => {
                // No additional logic needed for other cases
            });
    },
});

export default activitySlice.reducer;
