import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { backUrl } from '../constants';

export const fetchTeamDetails = createAsyncThunk('team/fetchTeamDetails', async (teamid) => {
    const headers = teamid.headers;
    const response = await axios.post(`${backUrl}team/getteamdetails`, { teamid }, { headers });
    return response.data;
});

export const fetchTeamActivity = createAsyncThunk('activity/fetchTeamActivity', async (teamid) => {
    const headers = teamid.headers;
    const response = await axios.post(`${backUrl}activity/fetchteamactivity`, { teamid }, { headers });
    return response.data;
})

export const findMemberByEmail = createAsyncThunk('team/findMemberByEmail', async ({ email, teamid, headers }) => {
    const response = await axios.post(`${backUrl}team/findmembersbyemail`, { email, teamid }, { headers });
    return response.data;
});

export const addUser = createAsyncThunk('team/addUser', async ({ teamId, userId, headers }) => {
    await axios.post(`${backUrl}team/adduser`, { teamId, userId }, { headers });
});

export const addAdmin = createAsyncThunk('team/addAdmin', async ({ teamId, userId, headers }) => {
    await axios.post(`${backUrl}team/addadmin`, { teamId, userId }, { headers });
});

export const makeAdmin = createAsyncThunk('team/makeAdmin', async ({ teamid, userid, headers }) => {
    await axios.post(`${backUrl}team/makeadmin`, { teamid, userid }, { headers });
});

export const removeUser = createAsyncThunk('team/removeUser', async ({ teamid, userid, headers }) => {
    await axios.post(`${backUrl}team/removemember`, { teamid, userid }, { headers });
});

const teamSlice = createSlice({
    name: 'teamData',
    initialState: {
        team: {},
        searchedMember: "",
        noUserError: false,
        selectedMember: null,
        teamActivity: "",
        eligibleToRemoveUser: false,
        eligibleToMakeAdmin: false,
        eligibleToAdduser: false,
        eligibleToAddAdmin: false,
        addUserError: null,
        removeUserError: null,
        status: 'idle',
        error: null,
    },
    reducers: {
        setSelectedMember: (state, action) => {
            state.selectedMember = action.payload;
        },
        clearSearchData: (state) => {
            state.noUserError = false;
            state.searchedMember = "";
        },
        checkPermission: (state, action) => {
            const { selected, user, team } = action.payload;
            if (selected.userid === user.userId) {
                state.eligibleToMakeAdmin = false;
                state.eligibleToRemoveUser = false;
            } else {
                if (team.role === 'Owner') {
                    if (selected.role === 'User') {
                        state.eligibleToMakeAdmin = true;
                        state.eligibleToRemoveUser = true;
                    } else {
                        state.eligibleToMakeAdmin = false;
                        state.eligibleToRemoveUser = true;
                    }
                } else if (team.role === 'Admin') {
                    if (selected.role === 'User') {
                        state.eligibleToMakeAdmin = false;
                        state.eligibleToRemoveUser = true;
                    } else {
                        state.eligibleToMakeAdmin = false;
                        state.eligibleToRemoveUser = false;
                    }
                } else {
                    state.eligibleToMakeAdmin = false;
                    state.eligibleToRemoveUser = false;
                }
            }
        },
        checkAddPermission: (state, action) => {
            const { team } = action.payload;
            if (team.role === 'Owner') {
                state.eligibleToAdduser = true;
                state.eligibleToAddAdmin = true;
            }
            if (team.role === 'Admin') {
                state.eligibleToAdduser = true;
                state.eligibleToAddAdmin = false;
            }
            if (team.role === 'User') {
                state.eligibleToAdduser = false;
                state.eligibleToAddAdmin = false;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTeamDetails.fulfilled, (state, action) => {
                state.team = action.payload;
            })
            .addCase(fetchTeamActivity.fulfilled, (state, action) => {
                state.teamActivity = action.payload;
            })
            .addCase(findMemberByEmail.fulfilled, (state, action) => {
                state.searchedMember = action.payload;
                state.noUserError = false;
            })
            .addCase(findMemberByEmail.rejected, (state) => {
                state.noUserError = true;
            })
            .addCase(addUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
            });
    },
});

export const { setSelectedMember, clearSearchData, checkPermission } = teamSlice.actions;
export default teamSlice.reducer;
