import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Login.css';
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, googleLogin  } from '../../Reducers/authSlice';
import { clientId } from '../../constants';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const token = useSelector((state) => state.auth.token);
    
    const [loginError, setLoginError] = useState("");
    const [data, setData] = useState({ email: '', password: '' });
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showOtp, setShowOtp] = useState(false);

    //This is for email validation
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    //This is handling the change requests
    const changeHandler = (e) => {
        setData({
            ...data, [e.target.name]: e.target.value
        })
    };

    //This is handling the submit request
    const submitHandler = async (e) => {
        e.preventDefault();

        // Reset previous error messages
        setEmailError('');
        setPasswordError('');
        setLoginError('');

        // Perform validation
        if (data.email === '') {
            setEmailError('Email is required.');
        } else if (!validateEmail(data.email)) {
            setEmailError('Invalid email format.');
        }

        if (data.password === '') {
            setPasswordError('Password is required.');
        }

        // If there are no errors, proceed with login logic
        if (data.email && validateEmail(data.email) && data.password) {
            try {
                dispatch(loginUser(data));
                if (token) {
                    navigate('/');
                }
            } catch (error) {
                console.log(error);

                //If server connection failed
                try {
                    setLoginError(error.response.data.message);
                } catch (error) {
                    setLoginError("Something went wrong");
                }
            };
        };
    };

    const handleGoogleLogin = () => {
        const redirectUri = encodeURIComponent(`${window.location.origin}/auth/google/callback`);
        const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=token&scope=profile email`;
        window.location.href = googleAuthUrl;
    };

    return (
        <>
            <div className='container-fluid login_container my-5'>
                <div className='row px-5'>
                    <div className='col-lg-6 login_description'>
                        <img src="/Images/LoginSide.JPG" className='login_side_image' />
                    </div>

                    <div className='col-lg-6 login_frame'>
                        <div className='row home_link_row'>
                            <Link className='go_to_home_link' to="/">Go to Home</Link>
                        </div>
                        <div className='row'>
                            <img src="/Images/ScrapodileLogo.png" className='logo_login_image' />
                            <h4 className='logo_login_text'>Scrapodile</h4>
                        </div>

                            <div className='row'>
                                <div className='row pb-2'>
                                    <h5 className='login_heading'>Sign In</h5>
                                    <p className='login_text'>Access the Scrapodile dashboard panel using your email and password.</p>
                                </div>
                                <form onSubmit={submitHandler}>
                                    <div className='row'>
                                        <label className='login_labels'>Email</label>
                                        <div className='col-12'>
                                            <input className='input_login' type='text' name='email' value={data.email} onChange={changeHandler} />
                                            <div className='mail_icon_login'>
                                                <MdOutlineMailOutline />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row input_row_login'>
                                        <label className='login_labels'>Password</label>
                                        <div className='col-12'>
                                            <input className='input_login' type={showPassword ? 'text' : 'password'} name='password' value={data.password} onChange={changeHandler} />
                                            <div className='mail_icon_login password_icon'>
                                                {showPassword ? <IoEyeOutline onClick={() => setShowPassword(!showPassword)} /> : <IoEyeOffOutline onClick={() => setShowPassword(!showPassword)} />}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <Link className='forgot_password_login' to="/forgotpassword">Forgot Password ?</Link>

                                        <div className='col-12'>
                                            <button type='submit' className='login_button'>Sign In</button>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <p className='new_login_text'>New on our platform? <Link className='new_login_link' to='/register'>Create an account</Link></p>
                                    </div>
                                    <h6 className='or_login_text'>OR, Sign in with</h6>
                                    <div className='row px-3'>
                                        <div className='col text-center social_link_button_login facebook_login'>
                                            <FaFacebook />
                                        </div>
                                        <div className='col text-center social_link_button_login google_login' onClick={handleGoogleLogin}>
                                            <FaGoogle />
                                        </div>
                                        <div className='col text-center social_link_button_login apple_login'>
                                            <FaApple />
                                        </div>
                                    </div>
                                </form>
                            </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default Login