import React, { useEffect, useState } from 'react';
import './ViewResults.css';
import { MdPlace } from 'react-icons/md';
import { CgSearch } from 'react-icons/cg';
import { CgDatabase } from 'react-icons/cg';
import { BiSolidUser } from 'react-icons/bi';
import { MdAccessTimeFilled } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { backUrl } from '../../constants';
import axios from 'axios';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { fetchTeamDetails } from '../../Reducers/teamDataSlice';
import { useDispatch, useSelector } from 'react-redux';


const MySwal = withReactContent(Swal);

const ViewResults = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector(state => state.auth.token);
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const { teamData } = useSelector(state => state.team);
  const { team } = useSelector(state => state.teamData);

  const [resultData, setResultData] = useState([]);
  const [searchKeyWord, setSearchKeyword] = useState("");
  const [searchPlace, setSearchPlace] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    if (teamData) {
      dispatch(fetchTeamDetails({ teamid: teamData[0].teamid, headers }));
    }
  }, [teamData]);

  const getAllResult = async () => {
    try {
      if (team && team.length > 0) {
        const response = await axios.post(backUrl + 'data/getAllResult', { linkedTeam: team[0].teamdetails[0]._id }, { headers });

        setResultData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getFirstName = (sentence) => {

    var words = sentence.split(" ");

    if (words.length > 0) {
      return words[0];
    } else {
      return null;
    }
  }

  const convertDateFormat = (inputDate) => {
    var date = new Date(inputDate);

    var day = ('0' + date.getDate()).slice(-2);
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var year = date.getFullYear();
    var hours = ('0' + date.getHours()).slice(-2);
    var minutes = ('0' + date.getMinutes()).slice(-2);

    var amOrPm = date.getHours() >= 12 ? 'PM' : 'AM';

    var formattedDate = day + '-' + month + '-' + year + ' ' + hours + ':' + minutes + ' ';

    return formattedDate;
  }

  const deleteDataHandler = async (dataId) => {
    try {
      const confirmResult = await MySwal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
      });

      if (confirmResult.isConfirmed) {
        const response = await axios.post(backUrl + 'data/deleteData', { dataId }, { headers });

        console.log(response.data);
        getAllResult();
      }

    } catch (error) {
      console.log(error);
    }
  }

  const extractUniqueUsers = () => {
    const uniqueUsers = new Set();
    resultData.forEach((item) => {
      uniqueUsers.add(getFirstName(item.user[0].fullname));
    });
    return Array.from(uniqueUsers);
  };

  const users = extractUniqueUsers();


  const handleKeywordChange = (e) => {
    setSearchKeyword(e.target.value);
  };
  const handlePlaceChange = (e) => {
    setSearchPlace(e.target.value);
  };
  const handleUserChange = (e) => {
    setSelectedUser(e.target.value);
  };
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const filteredData = [...resultData];

  const applyFilter = () => {
    return filteredData.filter((item) => {
      const keywordMatch = item.keyword.toLowerCase().includes(searchKeyWord.toLowerCase());
      const placeMatch = item.place.toLowerCase().includes(searchPlace.toLowerCase());
      const userMatch =
        selectedUser === "" || getFirstName(item.user[0].fullname).toLowerCase() === selectedUser.toLowerCase();
      const dateMatch = selectedDate === "" || item.createdAt.includes(selectedDate); // Adjust date matching logic

      return keywordMatch && placeMatch && userMatch && dateMatch;
    });
  };

  const countDataItems = () => {
    return applyFilter().length;
  };

  const sumDataLength = () => {
    return applyFilter().reduce((acc, item) => acc + item.dataLength, 0);
  };

  useEffect(() => {
    if (team && team.length > 0) {
      getAllResult();
    }
  }, []);

  console.log('teamData', teamData);
  console.log('team', team);
  console.log(applyFilter());
  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <h5 className='text-center my-3 all_results_heading'>ALL RESULTS</h5>
        </div>
        <div className='row pb-2'>
          <div className='col-lg-4 result_items2'>
            Results {countDataItems()}, Data {sumDataLength()}
          </div>
          <div className='col-lg-8 d-flex justify-content-end'>
            <input className='filter_inputs_result' type='text' placeholder='Keyword' value={searchKeyWord} onChange={handleKeywordChange} />
            <input className='filter_inputs_result' type='text' placeholder='Place' value={searchPlace} onChange={handlePlaceChange} />
            <select
              className='filter_inputs_result'
              value={selectedUser}
              onChange={handleUserChange}
            >
              <option value="">All Users</option>
              {users.map((user, index) => (
                <option key={index} value={user}>
                  {user}
                </option>
              ))}
            </select>
            <input className='filter_inputs_result' type='date' value={selectedDate} onChange={handleDateChange} />
          </div>
        </div>
        {applyFilter().map(item => (
          <div className='row result_row text-center my-2' key={item._id}>
            <div className='col'>
              <h6 className='result_items'><CgSearch /></h6>
              <h6 className='result_items'>{item.keyword}</h6>
            </div>
            <div className='col'>
              <h6 className='result_items'><MdPlace /></h6>
              <h6 className='result_items'>{item.place}</h6>
            </div>
            <div className='col'>
              <h6 className='result_items'><CgDatabase /></h6>
              <h6 className='result_items'>{item.dataLength}</h6>
            </div>
            <div className='col'>
              <h6 className='result_items'><BiSolidUser /></h6>
              <h6 className='result_items'>{getFirstName(item.user[0].fullname)}</h6>
            </div>
            <div className='col'>
              <h6 className='result_items'><MdAccessTimeFilled /></h6>
              <h6 className='result_items'>{convertDateFormat(item.createdAt)}</h6>
            </div>
            <div className='col'>
              <button className='view_button mx-1 mb-1' onClick={() => navigate(`/dashboard/viewdata/${item._id}`)}>View</button>
              <button className='delete_button mx-1 mb-1' onClick={() => deleteDataHandler(item._id)}>Delete</button>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default ViewResults