import React, { useState, useEffect } from 'react';
import { Route, Routes, BrowserRouter as Router, BrowserRouter, Link, Outlet } from 'react-router-dom';
import UpperBar from './UpperBar';
import { RxDashboard } from "react-icons/rx";
import { FiPlus } from "react-icons/fi";
import { MdOutlineLeaderboard, MdOutgoingMail, MdEmail, MdDrafts } from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { FaDollarSign, FaQuestion } from "react-icons/fa6";
import { FaFileInvoiceDollar, FaUsersGear } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa";
import { SiGoogledocs } from "react-icons/si";
import { RiGitRepositoryPrivateLine } from "react-icons/ri";
import { AiFillPlusSquare } from "react-icons/ai";
import './Dashboard.css';
import Home from '../Pages/Home';
import Leads from '../Reports/Leads';
import GenerateLeads from '../CRM/GenerateLeads';
import ViewResults from '../CRM/ViewResults';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { backUrl } from '../../constants';
import ViewData from '../CRM/ViewData';
import Followup from '../CRM/Followup';
import Profile from './Profile';
import MainIndex from './MainIndex';
import TeamManage from './TeamManage';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamData } from '../../Reducers/teamSlice';
import ReceivedMail from '../CRM/ReceivedMail';
import SendMail from '../CRM/SendMail';
import NewMail from '../CRM/NewMail';
import DraftsMail from '../CRM/DraftsMail';

const Dashboard = () => {
    const dispatch = useDispatch();
    const { teamData, loading, error } = useSelector(state => state.team);
    const token = useSelector(state => state.auth.token);
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };

    useEffect(() => {
        dispatch(getTeamData(headers));
    }, [dispatch]);
    return (
        <>
            <UpperBar />

            <div className='container-fluid dashboard_container'>
                <div className='row'>

                    {/* Sidebar */}
                    <div className='col-2 py-3 sidebar_dashboard'>

                        {/* Main */}
                        <div className='row mt-3'>
                            <div className='col sidebar_column_upper'>
                                <span className='ps-2'>MAIN</span>
                            </div>
                        </div>
                        <div className='row ps-3'>
                            <div className='col sidebar_column'>
                                <RxDashboard />
                                <Link className='ps-3 sidemenu_link' to="/dashboard">Dashboard</Link>
                            </div>
                        </div>

                        {/* Leads */}
                        <div className='row mt-3'>
                            <div className='col sidebar_column_upper'>
                                <span className='ps-2'>LEADS</span>
                            </div>
                        </div>
                        <div className='row ps-3'>
                            <div className='col sidebar_column'>
                                <MdOutlineLeaderboard />
                                <Link className='ps-3 sidemenu_link' to='/dashboard/leads'>All Leads</Link>
                            </div>
                        </div>
                        <div className='row ps-3'>
                            <div className='col sidebar_column'>
                                <FiPlus />
                                <Link className='ps-3 sidemenu_link' to='/dashboard/generateleads'>Generate Leads</Link>
                            </div>
                        </div>

                        {/* Reports */}
                        <div className='row mt-3'>
                            <div className='col sidebar_column_upper'>
                                <span className='ps-2'>REPORTS</span>
                            </div>
                        </div>
                        <div className='row ps-3'>
                            <div className='col sidebar_column'>
                                <MdOutlineLeaderboard />
                                <Link className='ps-3 sidemenu_link' to='/dashboard/leadsresports'>Leads Reports</Link>
                            </div>
                        </div>
                        <div className='row ps-3'>
                            <div className='col sidebar_column'>
                                <TbReportAnalytics />
                                <Link className='ps-3 sidemenu_link'>Scrapping Reports</Link>
                            </div>
                        </div>
                        <div className='row ps-3'>
                            <div className='col sidebar_column'>
                                <HiOutlineDocumentReport />
                                <Link className='ps-3 sidemenu_link'>Transaction Reports</Link>
                            </div>
                        </div>

                        {/* Mail */}
                        <div className='row mt-3'>
                            <div className='col sidebar_column_upper'>
                                <span className='ps-2'>Mail</span>
                            </div>
                        </div>
                        <div className='row ps-3'>
                            <div className='col sidebar_column'>
                                <AiFillPlusSquare />
                                <Link className='ps-3 sidemenu_link' to="/dashboard/newmail">New Mail</Link>
                            </div>
                        </div>
                        <div className='row ps-3'>
                            <div className='col sidebar_column'>
                                <MdEmail />
                                <Link className='ps-3 sidemenu_link' to="/dashboard/receivedmail">Received Mails</Link>
                            </div>
                        </div>
                        <div className='row ps-3'>
                            <div className='col sidebar_column'>
                                <MdOutgoingMail />
                                <Link className='ps-3 sidemenu_link' to="/dashboard/sendmail">Send Mails</Link>
                            </div>
                        </div>
                        <div className='row ps-3'>
                            <div className='col sidebar_column'>
                                <MdDrafts />
                                <Link className='ps-3 sidemenu_link' to="/dashboard/draftmail">Draft Mails</Link>
                            </div>
                        </div>

                        {/* Bills */}
                        <div className='row mt-3'>
                            <div className='col sidebar_column_upper'>
                                <span className='ps-2'>BILLING</span>
                            </div>
                        </div>
                        <div className='row ps-3'>
                            <div className='col sidebar_column'>
                                <FaDollarSign />
                                <Link className='ps-3 sidemenu_link'>Account</Link>
                            </div>
                        </div>
                        <div className='row ps-3'>
                            <div className='col sidebar_column'>
                                <FaFileInvoiceDollar />
                                <Link className='ps-3 sidemenu_link'>Payment History</Link>
                            </div>
                        </div>

                        {/* Setting */}
                        <div className='row mt-3'>
                            <div className='col sidebar_column_upper'>
                                <span className='ps-2'>SETTING</span>
                            </div>
                        </div>
                        <div className='row ps-3'>
                            <div className='col sidebar_column'>
                                <FaRegUser />
                                <Link className='ps-3 sidemenu_link' to='/dashboard/profile'>Manage Profile</Link>
                            </div>
                        </div>
                        <div className='row ps-3'>
                            <div className='col sidebar_column'>
                                <FaUsersGear />
                                <Link className='ps-3 sidemenu_link' to="/dashboard/manageteam">Manage Team</Link>
                            </div>
                        </div>

                        {/* Help */}
                        <div className='row mt-3'>
                            <div className='col sidebar_column_upper'>
                                <span className='ps-2'>HELP</span>
                            </div>
                        </div>
                        <div className='row ps-3'>
                            <div className='col sidebar_column'>
                                <FaQuestion />
                                <Link className='ps-3 sidemenu_link'>FAQ</Link>
                            </div>
                        </div>
                        <div className='row ps-3'>
                            <div className='col sidebar_column'>
                                <SiGoogledocs />
                                <Link className='ps-3 sidemenu_link'>Documentation</Link>
                            </div>
                        </div>
                        <div className='row ps-3'>
                            <div className='col sidebar_column'>
                                <RiGitRepositoryPrivateLine />
                                <Link className='ps-3 sidemenu_link'>Terms & Conditions</Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-10 main_space'>
                        <Routes>
                            <Route exact path="/" element={<MainIndex />} />
                            <Route exact path="/leads" element={<ViewResults />} />
                            <Route exact path="/generateleads" element={<GenerateLeads />} />
                            <Route exact path="/leadsresports" element={<Leads />} />
                            <Route exact path="/viewdata/:id" element={<ViewData />} />
                            <Route exact path="/followup/:id" element={<Followup />} />
                            <Route exact path="/profile" element={<Profile />} />
                            <Route exact path="/manageteam" element={<TeamManage />} />
                            <Route exact path="/receivedmail" element={<ReceivedMail />} />
                            <Route exact path="/sendmail" element={<SendMail />} />
                            <Route exact path="/newmail" element={<NewMail />} />
                            <Route exact path="/newmail/:id" element={<NewMail />} />
                            <Route exact path="/draftmail" element={<DraftsMail />} />
                        </Routes>
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard