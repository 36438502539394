import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Home.css';
import Navbar from '../Sidebar/Navbar';
import Hero from './Hero';
import Footer from './Footer';
import Services from './Services';
import WhatWeDo from './WhatWeDo';
import Contact from './Contact';
import Pricing from './Pricing';

const Home = () => {

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname !== '/') {
            navigate('/');
        }
    }, []);

    return (
        <>
            <Navbar />
            <Hero />
            <Services />
            <WhatWeDo />
            <Pricing />
            <Contact />
            <Footer />
        </>
    )
}

export default Home