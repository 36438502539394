import React from 'react';
import { FaAngleRight } from "react-icons/fa6";

const price = [
    {
        name: "TRIAL",
        price: 0,
        limit: "100",
        feature1: "Realtime Execution",
        feature2: "1 parallel task",
        feature3: "CSV, XLSX, JSON export",
        feature4: "Duplicate email filter",
        help: "Community support",
    },
    {
        name: "STANDARD",
        price: 5,
        limit: "1000",
        feature1: "Realtime Execution",
        feature2: "1 parallel task",
        feature3: "CSV, XLSX, JSON export",
        feature4: "Duplicate email filter",
        help: "Email support",
    },
    {
        name: "BUSINESS",
        price: 40,
        limit: "100000",
        feature1: "Realtime Execution",
        feature2: "2 parallel task",
        feature3: "CSV, XLSX, JSON export",
        feature4: "Duplicate email filter",
        help: "Priority E-mail support",
    },
    {
        name: "PROFESSIONAL",
        price: 100,
        limit: "2 million",
        feature1: "Realtime Execution",
        feature2: "3 parallel task",
        feature3: "CSV, XLSX, JSON export",
        feature4: "Duplicate email filter",
        help: "Priority E-mail support",
    },
]

const Pricing = () => {
    return (
        <>
            <div className='container-fluid py-5' id='pricing'>
                <div className='row d-flex justify-content-center'>
                    <h2 className='text-center pricing_header'>Simple and Transparent Pricing</h2>
                    <p className='text-center my-1'>Find the right plan for you, or seek help from experts <span className='talk_to_us_button pb-1'>Talk to us <FaAngleRight /></span></p>
                    <div className='snip1404'>
                        {price.map(item => (
                            <div className='plan'>
                                <header>
                                    <h4 class="plan-title">
                                        {item.name}
                                    </h4>
                                    <div class="plan-cost"><span class="plan-price">${item.price}</span><span class="plan-type">/month</span></div>
                                </header>
                                <ul class="plan-features">
                                    <li><i class="ion-checkmark"> </i>{item.limit} data scrapping</li>
                                    <li><i class="ion-checkmark"> </i>{item.feature1}</li>
                                    <li><i class="ion-checkmark"> </i>{item.feature2}</li>
                                    <li><i class="ion-checkmark"> </i>{item.feature3}</li>
                                    <li><i class="ion-checkmark"> </i>{item.feature4}</li>
                                    <li><i class="ion-checkmark"> </i>{item.help}</li>
                                </ul>
                                <div class="plan-select"><a href="">Select Plan</a></div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='row d-flex justify-content-center mb-5'>
                    <div className='col-lg-10 py-4 unique_case_section'>
                        <div className='row d-flex justify-content-center'>
                            <h3 className='text-center'>Have a unique use-case you'd like to discuss?</h3>
                            <p className='text-center'>We're happy to help you find out what your organisation needs</p>
                            <button className='talk_to_us_button2'>TALK TO US</button>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Pricing