import React, { useEffect, useRef, useState } from 'react';
import JoditEditor from 'jodit-react';
import './NewMail.css';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { backUrl } from '../../constants';
import { useParams } from 'react-router-dom';


const NewMail = () => {
    const params = useParams();

    const { id } = params;

    const editor = useRef(null);
    const { token } = useSelector((state) => state.auth);

    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };

    const [userData, setUserData] = useState({});
    const [mailTo, setMailTo] = useState("");
    const [mailToName, setMailToName] = useState("");
    const [content, setContent] = useState("");
    const [subject, setSubject] = useState("");
    const [hasChanges, setHasChanges] = useState(false);
    const [draftId, setDraftId] = useState(null);

    const prevMailTo = useRef(mailTo);
    const prevMailToName = useRef(mailToName);
    const prevContent = useRef(content);
    const prevSubject = useRef(subject);

    const sendMailNow = async () => {
        try {
            if (content && subject) {
                const response = await axios.post(backUrl + 'mail/send', {
                    mailFrom: userData.name,
                    mailId: userData.email,
                    toEmail: mailTo,
                    toName: mailToName,
                    subject: subject,
                    content: content,
                }, { headers });
                alert(response.data);

                if (response.status === 200) {
                    setMailTo("");
                    setMailToName("");
                    setContent("");
                    setSubject("");
                    setHasChanges(false);

                    await axios.post(backUrl + 'mail/deletedraft', { id: draftId }, { headers });
                }
            }
        } catch (error) {
            console.log(error);
            alert("Something went wrong");
        }
    }

    const saveAsDraft = async () => {
        try {
            const response = await axios.post(backUrl + 'mail/savedraft', {
                draftId: draftId,
                toEmail: mailTo,
                toName: mailToName,
                subject: subject,
                content: content,
            }, { headers });

            console.log(response.data);
            setDraftId(response.data._id);
            setHasChanges(false);

            // Reset previous values to current ones
            prevMailTo.current = mailTo;
            prevMailToName.current = mailToName;
            prevContent.current = content;
            prevSubject.current = subject;
        } catch (error) {
            console.log(error);
        }
    }

    const getDraftDetails = async () => {
        try {
            const response = await axios.post(backUrl + 'mail/getdraftdetails', { id }, { headers });
            if (response.data) {
                setDraftId(response.data._id);
                setMailTo(response.data.toEmail);
                setMailToName(response.data.toName);
                setContent(response.data.content);
                setSubject(response.data.subject);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (token) {
            const decoded = jwtDecode(token);
            setUserData(decoded);
        }
    }, [token]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (
                mailTo !== prevMailTo.current ||
                mailToName !== prevMailToName.current ||
                content !== prevContent.current ||
                subject !== prevSubject.current
            ) {
                saveAsDraft();
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [mailTo, mailToName, content, subject]);

    useEffect(() => {
        const handleChanges = () => {
            if (
                mailTo !== prevMailTo.current ||
                mailToName !== prevMailToName.current ||
                content !== prevContent.current ||
                subject !== prevSubject.current
            ) {
                setHasChanges(true);
            } else {
                setHasChanges(false);
            }
        };

        handleChanges();
    }, [mailTo, mailToName, content, subject]);

    useEffect(() => {
        if (id) {
            getDraftDetails();
        }
    }, []);

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <h5 className='text-center my-3 all_results_heading'>NEW MAIL</h5>

                    <div className='col-lg-12'>
                        <p className='mail_from_new_mail'>From: <span className='mail_from_new_mail_span'>{userData.email} [ {userData.name} ]</span></p>
                    </div>
                    <div className='col-lg-12 pb-3'>
                        <p className='text-black fw-bold mt-1 send_mail_subject'>To (Mail)</p>
                        <input type='text' name='mailTo' className="form-control mb-1" placeholder='To' value={mailTo} onChange={(e) => setMailTo(e.target.value)} />
                    </div>
                    <div className='col-lg-12 pb-3'>
                        <p className='text-black fw-bold mt-1 send_mail_subject'>To (Name)</p>
                        <input type='text' name='mailToName' className="form-control mb-1" placeholder='To' value={mailToName} onChange={(e) => setMailToName(e.target.value)} />
                    </div>
                    <div className='col-lg-12 pb-3'>
                        <p className='text-black fw-bold mt-1 send_mail_subject'>Subject</p>
                        <input type='text' name='subject' className="form-control mb-1" placeholder='Subject' value={subject} onChange={(e) => setSubject(e.target.value)} />
                    </div>
                </div>

                <JoditEditor
                    ref={editor}
                    value={content}
                    onChange={newContent => setContent(newContent)}
                />

                <div className='row my-3 d-flex justify-content-end'>
                    <div className='col-2'>
                        {hasChanges ? (
                            <button className='new_mail_buttons_draft' onClick={saveAsDraft} >Save to draft</button>
                        ) : (
                            <button className='new_mail_buttons_draft_saved' >Saved to draft</button>
                        )}
                    </div>
                    <div className='col-2'>
                        <button className='new_mail_buttons_cancel' onClick={() => setContent("")}>Clear</button>
                    </div>
                    <div className='col-2'>
                        <button className='new_mail_buttons_send' onClick={sendMailNow}>Send</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewMail;
