import React from 'react';
import './Home.css';

const Footer = () => {
    return (
        <div className='container-fluid service_container pb-5'>
            <div className='row footer_row'>
                <div className='col-lg-10 text-center copyrights_footer'>
                    Copyright © 2024 Ajatus Software Pvt. Ltd. All Rights Reserved.
                </div>
            </div>
        </div>
    )
}

export default Footer