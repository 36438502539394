import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdLockOutline, MdOutlineMailOutline } from 'react-icons/md';
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5';
import { sendOtp, verifyOtp, setPassword, resetForgotPasswordState } from '../../Reducers/forgotPasswordSlice';

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const { loading, showEmailPage, showOtpPage, showPasswordPage, email, otpReference } = useSelector(state => state.forgotPassword);

    const [inputEmail, setInputEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [password, setPasswordState] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const sendOtpHandler = (e) => {
        e.preventDefault();
        dispatch(sendOtp(inputEmail)).then(() => setInputEmail(''));
    };

    const submitOtpHandler = (e) => {
        e.preventDefault();
        dispatch(verifyOtp({ otpReference, otp })).then(() => setOtp(''));
    };

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert('Passwords do not match!');
            return;
        }
        dispatch(setPassword({ email, password })).then(() => {
            setPasswordState('');
            setConfirmPassword('');
        });
    };

    const handleChangeEmail = () => {
        dispatch(resetForgotPasswordState());
    };

    return (
        <div className='container-fluid login_container my-5'>
            <div className='row px-5'>
                <div className='col-lg-6 login_description'>
                    <img src="/Images/LoginSide.JPG" className='login_side_image' />
                </div>
                <div className='col-lg-6 login_frame'>
                    <div className='row home_link_row'>
                        <Link className='go_to_home_link' to="/">Go to Home</Link>
                    </div>
                    <div className='row'>
                        <img src="/Images/ScrapodileLogo.png" className='logo_login_image' alt="Scrapodile Logo" />
                        <h4 className='logo_login_text'>Scrapodile</h4>
                    </div>
                    <div className='row pb-2'>
                        <h5 className='login_heading'>Forgot Password</h5>
                        {showEmailPage && (
                            <p className='login_text'>Enter your email and we will send an OTP to reset your password.</p>
                        )}
                        {showOtpPage && (
                            <p className='login_text'>Enter the OTP sent to your email.</p>
                        )}
                        {showPasswordPage && (
                            <p className='login_text'>Enter your new password.</p>
                        )}
                    </div>

                    {showEmailPage && (
                        <form onSubmit={sendOtpHandler}>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <input
                                        type="email"
                                        placeholder='Email'
                                        className='input_login'
                                        value={inputEmail}
                                        onChange={(e) => setInputEmail(e.target.value)}
                                    />
                                    <div className='mail_icon_login'>
                                        <MdOutlineMailOutline />
                                    </div>
                                </div>
                            </div>
                            <div className='row pt-2'>
                                <div className='col-lg-12'>
                                    <button className='login_button' type='submit' disabled={loading}>Send OTP</button>
                                </div>
                            </div>
                            <div className='row'>
                                <p className='new_login_text'>Already have access? <Link className='new_login_link' to='/login'>Login</Link></p>
                            </div>
                        </form>
                    )}

                    {showOtpPage && (
                        <form onSubmit={submitOtpHandler}>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <input
                                        type="text"
                                        placeholder='OTP'
                                        className='input_login'
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                    />
                                    <div className='mail_icon_login'>
                                        <MdLockOutline />
                                    </div>
                                </div>
                            </div>
                            <div className='row pt-2'>
                                <div className='col-lg-12'>
                                    <button className='login_button' type='submit' disabled={loading}>Submit</button>
                                </div>
                            </div>
                            <div className='row pt-2'>
                                <Link className='text-decoration-none'><span className='resend_otp'>Resend OTP</span></Link>
                            </div>
                            <div className='row'>
                                <p className='new_login_text'>Not your email? <span className='new_login_link' onClick={handleChangeEmail}>Change Email</span></p>
                            </div>
                        </form>
                    )}

                    {showPasswordPage && (
                        <form onSubmit={handlePasswordSubmit}>
                            <div className='row name_label_row'>
                                <label className='login_labels'>Password</label>
                                <div className='col-12'>
                                    <input
                                        className='input_login'
                                        type={showNewPassword ? 'text' : 'password'}
                                        name='password'
                                        value={password}
                                        onChange={(e) => setPasswordState(e.target.value)}
                                    />
                                    <div className='mail_icon_login'>
                                        {showNewPassword ? <IoEyeOutline onClick={() => setShowNewPassword(!showNewPassword)} /> : <IoEyeOffOutline onClick={() => setShowNewPassword(!showNewPassword)} />}
                                    </div>
                                </div>
                            </div>
                            <div className='row pt-3'>
                                <label className='login_labels'>Confirm Password</label>
                                <div className='col-12'>
                                    <input
                                        className='input_login'
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        name='confirm_password'
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                    <div className='mail_icon_login'>
                                        {showConfirmPassword ? <IoEyeOutline onClick={() => setShowConfirmPassword(!showConfirmPassword)} /> : <IoEyeOffOutline onClick={() => setShowConfirmPassword(!showConfirmPassword)} />}
                                    </div>
                                </div>
                            </div>
                            <div className='row pt-5'>
                                <div className='col-12'>
                                    <button type='submit' className='register_button' disabled={loading}>Submit</button>
                                </div>
                            </div>
                            <div className='row'>
                                <p className='new_login_text'>Already have access? <Link className='new_login_link' to='/login'>Login</Link></p>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;