import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { backUrl } from "../constants";

const initialState = {
    loading: false,
    success: false,
    error: null,
    showEmailPage: true,
    showOtpPage: false,
    showPasswordPage: false,
    email: '',
    otpReference: null,
};

// Async thunk for sending OTP (Step 1)
export const sendOtp = createAsyncThunk(
    'forgotPassword/sendOtp',
    async (email, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${backUrl}auth/send-forgot-password-otp`, { email });
            return { email, otpReference: response.data.otpReference }; // Assuming response contains { otpReference }
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Async thunk for verifying OTP (Step 2)
export const verifyOtp = createAsyncThunk(
    'forgotPassword/verifyOtp',
    async ({ otpReference, otp }, { rejectWithValue }) => {
        try {
            await axios.post(`${backUrl}auth/verify-otp`, { otpReference, otp });
            return true;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Async thunk for setting new password (Step 3)
export const setPassword = createAsyncThunk(
    'forgotPassword/setPassword',
    async ({ email, password }, { rejectWithValue }) => {
        try {
            await axios.post(`${backUrl}auth/reset-password`, { email, password });
            return true;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const forgotPasswordSlice = createSlice({
    name: 'forgotPassword',
    initialState,
    reducers: {
        resetForgotPasswordState: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        // Handle sendOtp (Step 1)
        builder
            .addCase(sendOtp.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(sendOtp.fulfilled, (state, action) => {
                state.loading = false;
                state.email = action.payload.email;
                state.otpReference = action.payload.otpReference;
                state.showEmailPage = false;
                state.showOtpPage = true;
            })
            .addCase(sendOtp.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });

        // Handle verifyOtp (Step 2)
        builder
            .addCase(verifyOtp.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(verifyOtp.fulfilled, (state) => {
                state.loading = false;
                state.showOtpPage = false;
                state.showPasswordPage = true;
            })
            .addCase(verifyOtp.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });

        // Handle setPassword (Step 3)
        builder
            .addCase(setPassword.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(setPassword.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
                state.showPasswordPage = false;
                state.showEmailPage = true;
            })
            .addCase(setPassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { resetForgotPasswordState } = forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;
