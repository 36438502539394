import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BiSolidEdit } from "react-icons/bi";
import { IoMdSave } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import axios from 'axios';
import { backUrl } from '../../constants';
import { formatDate } from '../../Utility/formatDate';

const token = localStorage.getItem('token');

const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json', // Set the appropriate content type
};

const Profile = () => {
    const [userData, setUserData] = useState("");
    const [showEditGender, setShowEditGender] = useState(false);
    const [showEditBirthDate, setShowEditBirthDate] = useState(false);
    const [showEditPhoneNumber, setShowEditPhoneNumber] = useState(false);
    const [showEditEditCountry, setShowEditCountry] = useState(false);
    const [showEditCity, setShowEditCity] = useState(false);
    const [showEditAddress, setShowEditAddress] = useState(false);
    const [showEditZipCode, setShowEditZipCode] = useState(false);
    const [editGender, setEditGender] = useState(false);
    const [editBirthDate, setEditBirthDate] = useState(false);
    const [editPhoneNumber, setEditPhoneNumber] = useState(false);
    const [editCountry, setEditCountry] = useState(false);
    const [editCity, setEditCity] = useState(false);
    const [editAddress, setEditAddress] = useState(false);
    const [editZipCode, setEditZipCode] = useState(false);
    const [gender, setGender] = useState(false);
    const [birthDate, setBirthDate] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(false);
    const [country, setCountry] = useState(false);
    const [city, setCity] = useState(false);
    const [address, setAddress] = useState(false);
    const [zipCode, setZipCode] = useState(false);

    const fetchUserDetails = async () => {
        try {
            const response = await axios.get(backUrl + "auth/getuserdetails", { headers });

            setUserData(response.data);
            setEditGender(response.data.gender);
            setEditBirthDate(response.data.birthdate);
            setEditPhoneNumber(response.data.phoneNumber);
            setEditCountry(response.data.country);
            setEditCity(response.data.city);
            setEditAddress(response.data.address);
            setEditZipCode(response.data.zipCode);
            setGender(response.data.gender);
            setBirthDate(response.data.birthdate);
            setPhoneNumber(response.data.phoneNumber);
            setCountry(response.data.country);
            setCity(response.data.city);
            setAddress(response.data.address);
            setZipCode(response.data.zipCode);
        } catch (error) {
            console.log(error);
        }
    }

    const updateGender = async () => {
        try {
            await axios.post(backUrl + 'auth/updateuserdetails', { gender: editGender }, { headers });
            fetchUserDetails();
            setShowEditGender(false);
        } catch (error) {
            console.log(error);
        }
    }

    const updateBirthDate = async () => {
        try {
            await axios.post(backUrl + 'auth/updateuserdetails', { birthdate: editBirthDate }, { headers });
            fetchUserDetails();
            setShowEditBirthDate(false);
        } catch (error) {
            console.log(error);
        }
    }

    const updatePhoneNumber = async () => {
        try {
            await axios.post(backUrl + 'auth/updateuserdetails', { phoneNumber: editPhoneNumber }, { headers });
            fetchUserDetails();
            setShowEditPhoneNumber(false);
        } catch (error) {
            console.log(error);
        }
    }

    const updateCountry = async () => {
        try {
            await axios.post(backUrl + 'auth/updateuserdetails', { country: editCountry }, { headers });
            fetchUserDetails();
            setShowEditCountry(false);
        } catch (error) {
            console.log(error);
        }
    }

    const updateCity = async () => {
        try {
            await axios.post(backUrl + 'auth/updateuserdetails', { city: editCity }, { headers });
            fetchUserDetails();
            setShowEditCity(false);
        } catch (error) {
            console.log(error);
        }
    }

    const updateAddress = async () => {
        try {
            await axios.post(backUrl + 'auth/updateuserdetails', { address: editAddress }, { headers });
            fetchUserDetails();
            setShowEditAddress(false);
        } catch (error) {
            console.log(error);
        }
    }

    const updateZipCode = async () => {
        try {
            await axios.post(backUrl + 'auth/updateuserdetails', { zipCode: editZipCode }, { headers });
            fetchUserDetails();
            setShowEditZipCode(false);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchUserDetails();
    }, []);
    console.log(editGender);
    return (
        <>
            <div className='container-fluid'>
                <div className='row d-flex justify-content-center pt-3'>

                    {/* Image Side`` */}
                    <div className='col-lg-3 profile_image_side'>
                        <div className='row d-flex justify-content-center'>
                            <img src="/Images/profiledefault.png" className='profilephoto_user mt-4' />
                            <h5 className='text-center user_name'>{userData.fullname}</h5>
                            <h5 className='text-center user_email'>{userData.email}</h5>
                        </div>
                        <div className='row px-4'>
                            <div className='col d-flex justify-content-center pt-2 pb-4'>
                                <button className='edit_photo_profile'>Edit Photo</button>
                            </div>
                            <div className='col d-flex justify-content-center pt-2 pb-4'>
                                <Link to="/dashboard/manageteam"><button className='edit_photo_profile'>Edit Team</button></Link>
                            </div>
                        </div>
                    </div>

                    {/* Details Side`` */}
                    <div className='col-lg-9 d-flex align-items-center profile_deails_side'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <div className='row align-items-center py-2'>
                                    <div className='details_title'>Gender</div>
                                    {showEditGender ? (
                                        <div className='details_desc'><select id="cars" className='onelayer_input' value={editGender} onChange={(e) => setEditGender(e.target.value)} >
                                            <option value="">Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Not prefer to say">Not prefer to say</option>
                                        </select>
                                            <span className='edit_option_profile' onClick={updateGender}><IoMdSave /></span><span className='edit_option_profile ps-1' onClick={() => { setShowEditGender(false); setEditGender(gender); }}><MdCancel /></span></div>
                                    ) : (
                                        <div className='details_desc'>{userData.gender === "" ? "NA" : userData.gender} <span className='edit_option_profile' onClick={() => setShowEditGender(true)}><BiSolidEdit /></span></div>
                                    )}

                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='row align-items-center py-2'>
                                    <div className='details_title'>Birthday</div>
                                    {showEditBirthDate ? (
                                        <div className='details_desc'><input className='onelayer_input' type='date' value={editBirthDate} onChange={(e) => setEditBirthDate(e.target.value)} /> <span className='edit_option_profile' onClick={updateBirthDate}><IoMdSave /></span><span className='edit_option_profile ps-1' onClick={() => { setShowEditBirthDate(false); setEditBirthDate(birthDate); }}><MdCancel /></span></div>
                                    ) : (
                                        <div className='details_desc'>{userData.birthdate === null ? "NA" : formatDate(userData.birthdate)} <span className='edit_option_profile' onClick={() => setShowEditBirthDate(true)}><BiSolidEdit /></span></div>
                                    )}
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='row align-items-center py-2'>
                                    <div className='details_title'>Phone Number</div>
                                    {showEditPhoneNumber ? (
                                        <div className='details_desc'><input className='onelayer_input' value={editPhoneNumber} onChange={(e) => setEditPhoneNumber(e.target.value)} /> <span className='edit_option_profile' onClick={updatePhoneNumber}><IoMdSave /></span><span className='edit_option_profile ps-1' onClick={() => { setShowEditPhoneNumber(false); setEditPhoneNumber(phoneNumber); }}><MdCancel /></span></div>
                                    ) : (
                                        <div className='details_desc'>{userData.phoneNumber === null ? "NA" : userData.phoneNumber} <span className='edit_option_profile' onClick={() => setShowEditPhoneNumber(true)}><BiSolidEdit /></span></div>
                                    )}
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='row align-items-center py-2'>
                                    <div className='details_title'>Country</div>
                                    {showEditEditCountry ? (
                                        <div className='details_desc'><input className='onelayer_input' value={editCountry} onChange={(e) => setEditCountry(e.target.value)} /> <span className='edit_option_profile' onClick={updateCountry}><IoMdSave /></span><span className='edit_option_profile ps-1' onClick={() => { setShowEditCountry(false); setEditCountry(country); }}><MdCancel /></span></div>
                                    ) : (
                                        <div className='details_desc'>{userData.country === null ? "NA" : userData.country} <span className='edit_option_profile' onClick={() => setShowEditCountry(true)}><BiSolidEdit /></span></div>
                                    )}
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='row align-items-center py-2'>
                                    <div className='details_title'>City</div>
                                    {showEditCity ? (
                                        <div className='details_desc'><input className='onelayer_input' value={editCity} onChange={(e) => setEditCity(e.target.value)} /> <span className='edit_option_profile' onClick={updateCity}><IoMdSave /></span><span className='edit_option_profile ps-1' onClick={() => { setShowEditCity(false); setEditCity(city); }}><MdCancel /></span></div>
                                    ) : (
                                        <div className='details_desc'>{userData.city === null ? "NA" : userData.city} <span className='edit_option_profile' onClick={() => setShowEditCity(true)}><BiSolidEdit /></span></div>
                                    )}
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='row align-items-center py-2'>
                                    <div className='details_title'>Address</div>
                                    {showEditAddress ? (
                                        <div className='details_desc'><input className='onelayer_input' value={editAddress} onChange={(e) => setEditAddress(e.target.value)} /> <span className='edit_option_profile' onClick={updateAddress}><IoMdSave /></span><span className='edit_option_profile ps-1' onClick={() => { setShowEditAddress(false); setEditAddress(address); }}><MdCancel /></span></div>
                                    ) : (
                                        <div className='details_desc'>{userData.address === null ? "NA" : userData.address} <span className='edit_option_profile' onClick={() => setShowEditAddress(true)}><BiSolidEdit /></span></div>
                                    )}
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='row align-items-center py-2'>
                                    <div className='details_title'>ZIP Code</div>
                                    {showEditZipCode ? (
                                        <div className='details_desc'><input className='onelayer_input' value={editZipCode} onChange={(e) => setEditZipCode(e.target.value)} /> <span className='edit_option_profile' onClick={updateZipCode}><IoMdSave /></span><span className='edit_option_profile ps-1' onClick={() => { setShowEditZipCode(false); setEditZipCode(zipCode); }}><MdCancel /></span></div>
                                    ) : (
                                        <div className='details_desc'>{userData.zipCode === null ? "NA" : userData.zipCode} <span className='edit_option_profile' onClick={() => setShowEditZipCode(true)}><BiSolidEdit /></span></div>
                                    )}
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='row align-items-center py-2'>
                                    <div className='details_title'>Member Status</div>
                                    <div className='details_desc'>Active Member</div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='row align-items-center py-2'>
                                    <div className='details_title'>Registration Date</div>
                                    <div className='details_desc'>{formatDate(userData.createdAt)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile