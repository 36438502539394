import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FiUser } from 'react-icons/fi';
import { MdOutlineMailOutline, MdLockOutline } from 'react-icons/md';
import { FaFacebook, FaGoogle, FaApple } from 'react-icons/fa';
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5';
import { registerUser, verifyOtp, setPassword } from '../../Reducers/registerSlice';
import { clientId } from '../../constants';

const Register = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { showRegisterPage, showOtp, showPasswordPage, otpReference, registerError, token, isAuthenticated } = useSelector((state) => state.register);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [password, setPasswordInput] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleRegister = (e) => {
        e.preventDefault();
        dispatch(registerUser({ name, email }));
    };

    const handleOtpSubmit = (e) => {
        e.preventDefault();
        dispatch(verifyOtp({ otpReference, otp }));
    };

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (password === confirmPassword) {
            dispatch(setPassword({ name, email, password }));
        } else {
            alert("Passwords do not match!");
        }
    };

    const handleGoogleLogin = () => {
        const redirectUri = encodeURIComponent(`${window.location.origin}/auth/google/callback`);
        const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=token&scope=profile email`;
        window.location.href = googleAuthUrl;
    };

    return (
        <div className='container-fluid login_container my-5'>
            <div className='row px-5'>
                <div className='col-lg-6 login_description'>
                    <img src="/Images/LoginSide.JPG" className='login_side_image' />
                </div>
                <div className='col-lg-6 login_frame'>
                    <div className='row home_link_row'>
                        <Link className='go_to_home_link' to="/">Go to Home</Link>
                    </div>
                    <div className='row'>
                        <img src="/Images/ScrapodileLogo.png" className='logo_login_image' />
                        <h4 className='logo_login_text'>Scrapodile</h4>
                    </div>

                    {showRegisterPage && (
                        <div className='row'>
                            <div className='row pb-2'>
                                <h5 className='login_heading'>Register</h5>
                                <p className='login_text'>Create new Scrapodile account</p>
                            </div>
                            <form onSubmit={handleRegister}>
                                <div className='row name_label_row'>
                                    <label className='login_labels'>Name</label>
                                    <div className='col-12'>
                                        <input className='input_login' type='text' name='name' value={name} onChange={(e) => setName(e.target.value)} />
                                        <div className='mail_icon_login'>
                                            <FiUser />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className='login_labels'>Email</label>
                                    <div className='col-12'>
                                        <input className='input_login' type='text' name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                        <div className='mail_icon_login'>
                                            <MdOutlineMailOutline />
                                        </div>
                                    </div>
                                </div>
                                <div className='row pt-3'>
                                    <div className='col-1'>
                                        <span><input className='input_login' type='checkbox' name='terms' /></span>
                                    </div>
                                    <div className='col-11 d-flex align-items-center'>
                                        <label className='terms_label'>I agree to the <Link className='new_login_link' to='#'>Terms and Conditions</Link></label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <button type='submit' className='register_button'>Sign Up</button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <p className='new_login_text'>Already have an account? <Link className='new_login_link' to='/login'>Sign In Instead</Link></p>
                                </div>
                                <h6 className='or_login_text'>OR, Sign up with</h6>
                                <div className='row px-3'>
                                    <div className='col text-center social_link_button_login facebook_login'>
                                        <FaFacebook />
                                    </div>
                                    <div className='col text-center social_link_button_login google_login' onClick={handleGoogleLogin}>
                                        <FaGoogle />
                                    </div>
                                    <div className='col text-center social_link_button_login apple_login'>
                                        <FaApple />
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}

                    {showOtp && (
                        <div className='row py-5'>
                            <div className='row pb-2'>
                                <h5 className='login_heading'>Verify OTP</h5>
                                <p className='login_text'>Enter the OTP sent to your email</p>
                            </div>
                            <form onSubmit={handleOtpSubmit}>
                                <div className='row name_label_row'>
                                    <label className='login_labels'>Enter OTP</label>
                                    <div className='col-12'>
                                        <input className='input_login' type='text' name='otp' value={otp} onChange={(e) => setOtp(e.target.value)} />
                                        <div className='mail_icon_login'>
                                            <MdLockOutline />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <button type='submit' className='register_button'>Verify</button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <h6 className='resend_otp'><span className='resend_otp_text'>Resend OTP</span></h6>
                                </div>
                            </form>
                        </div>
                    )}

                    {showPasswordPage && (
                        <div className='row'>
                            <div className='row pb-2'>
                                <h5 className='login_heading'>Create Password</h5>
                                <p className='login_text'>Enter your password</p>
                            </div>
                            <form onSubmit={handlePasswordSubmit}>
                                <div className='row name_label_row'>
                                    <label className='login_labels'>Password</label>
                                    <div className='col-12'>
                                        <input className='input_login' type={showNewPassword ? 'text' : 'password'} name='password' value={password} onChange={(e) => setPasswordInput(e.target.value)} />
                                        <div className='mail_icon_login'>
                                            {showNewPassword ? <IoEyeOutline onClick={() => setShowNewPassword(!showNewPassword)} /> : <IoEyeOffOutline onClick={() => setShowNewPassword(!showNewPassword)} />}
                                        </div>
                                    </div>
                                </div>
                                <div className='row pt-3'>
                                    <label className='login_labels'>Confirm Password</label>
                                    <div className='col-12'>
                                        <input className='input_login' type={showConfirmPassword ? 'text' : 'password'} name='confirm_password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                        <div className='mail_icon_login'>
                                            {showConfirmPassword ? <IoEyeOutline onClick={() => setShowConfirmPassword(!showConfirmPassword)} /> : <IoEyeOffOutline onClick={() => setShowConfirmPassword(!showConfirmPassword)} />}
                                        </div>
                                    </div>
                                </div>
                                <div className='row pt-5'>
                                    <div className='col-12'>
                                        <button type='submit' className='register_button'>Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Register;
