import './App.css';
import { Route, Routes, BrowserRouter as Router, BrowserRouter } from 'react-router-dom';
// import Login from './Components/Auth/Login';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { clientId } from './constants';
import Sidebar from './Components/Sidebar/Sidebar';
import Login from './Components/Auth/Login';
import Home from './Components/Pages/Home';
import Dashboard from './Components/Dashboard/Dashboard';
import Register from './Components/Auth/Register';
import { useSelector } from 'react-redux';
import { loginUser } from './Reducers/authSlice';
import ForgotPassword from './Components/Auth/ForgotPassword';
import GoogleCallback from './Components/Auth/GoogleCallback';

function App() {
  const token = useSelector((state) => state.auth.token);
  const token2 = useSelector((state) => state.register.token);

  return (
    <>
      <BrowserRouter>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/login" exact element={token || token2 ? <Home /> : <Login />} />
            <Route path="/register" exact element={token || token2 ? <Home /> : <Register />} />
            <Route path="/auth/google/callback" exact element={<GoogleCallback />} />
            <Route path="/forgotpassword" exact element={token || token2 ? <Home /> : <ForgotPassword />} />
            <Route path="/dashboard/*" exact
              element={token || token2 ? (
                <Dashboard />
              )
                : (<Login />)}
            />
          </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
