import React from 'react';
import './Home.css';
import { useNavigate } from 'react-router-dom';

const token = localStorage.getItem('token');

const Hero = () => {
  const navgate = useNavigate();

  return (
    <div className='continer-fluid py-5' id='home'>
      <div className='row hero_row pb-2 pt-5'>
        {/* First Part */}
        <div className='col-lg-6 hero_headings_all'>
          <div className='row'>
            <h1 className='hero_heading1'>SCRAP YOUR DATA IN YOUR WAY</h1>
          </div>
          <div className='row pt-3'>
            <h5 className='hero_heading2'>Empower Your Business Growth with our Real-Time Data Scraping and Lead Management Tools</h5>
          </div>
          <div className='row button_row_hero'>
            <button className='hero_button_normal red_blue_hero_button' >Start a free trial</button>
            {token ? (
              <button className='hero_button_normal green_blue_hero_button' onClick={() => navgate('/dashboard')}>Dashboard</button>
            ) : (
              <button className='hero_button_normal green_blue_hero_button' onClick={() => navgate('/login')}>Login</button>
            )}
          </div>
        </div>

        {/* Second Part */}
        <div className='col-lg-6'>
          <img src="/Images/heroside.jpg" className='sidehero_image' />
        </div>
      </div>

    </div>
  )
}

export default Hero