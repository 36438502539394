import React, { useEffect, useRef, useState } from 'react'
import './Followup.css';
import axios from 'axios';
import { backUrl } from '../../constants';
import { useParams } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';
import { BiSave } from 'react-icons/bi';
import { RxCross2 } from 'react-icons/rx';
import JoditEditor from 'jodit-react';

const token = localStorage.getItem('token');

const headers = {
  'Authorization': `Bearer ${token}`,
  'Content-Type': 'application/json', // Set the appropriate content type
};

const Followup = () => {
  const editor = useRef(null);
  const { id } = useParams();

  const [resultData, setResultData] = useState({});
  const [mailResult, setMailResult] = useState([]);
  const [content, setContent] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [subject, setSubject] = useState("");
  const [isShow, setIsShow] = useState(true);
  const [flwupMessage, setFlwupMessage] = useState("");
  const [status, setStatus] = useState("");
  const [errorFlwup, setErrorFlwup] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [followupData, setFollowupData] = useState([]);
  const [showEmailEdit, setShowEmailEdit] = useState(false);
  const [showWebsiteEdit, setShowWebsiteEdit] = useState(false);
  const [showNumberEdit, setShowNumberEdit] = useState(false);
  const [showAddressEdit, setShowAddressEdit] = useState(false);
  const [showExpectedRevenueEdit, setShowExpectedRevenueEdit] = useState(false);
  const [showProbabilityEdit, setShowProbabilityEdit] = useState(false);
  const [showExpectedClosingEdit, setShowExpectedClosingEdit] = useState(false);
  const [emailEdit, setEmailEdit] = useState("");
  const [websiteEdit, setWebsiteEdit] = useState("");
  const [numberEdit, setNumberEdit] = useState("");
  const [addressEdit, setAddressEdit] = useState("");
  const [expectedRevenueEdit, setExpectedRevenueEdit] = useState("");
  const [probabilityEdit, setProbabilityEdit] = useState("");
  const [expectedClosingEdit, setExpectedClosingEdit] = useState("");

  const getSingleDetails = async (id) => {
    try {
      const response = await axios.post(backUrl + 'data/getSingleDetails', { dataId: id }, { headers });

      setResultData(response.data);

      setEmailEdit(response.data.email);
      setWebsiteEdit(response.data.website);
      setNumberEdit(response.data.phone_number);
      setAddressEdit(response.data.address);
      setExpectedRevenueEdit(response.data.expected_revenue);
      setProbabilityEdit(response.data.probability);

      // Convert the date format to "yyyy-mm-dd" before setting it in the input field
      const isoDate = new Date(response.data.expected_closing).toISOString().split('T')[0];
      setExpectedClosingEdit(isoDate);

      getAllMailByTo(response.data.email);
    } catch (error) {
      console.log(error);
    }
  }

  const formatDate = (dateData) => {
    const date = new Date(dateData);

    // Convert to IST (GMT +5:30)
    date.setMinutes(date.getMinutes() + 330); // 5 hours 30 minutes

    const day = String(date.getUTCDate()).padStart(2, '0'); // Ensure two digits
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Ensure two digits
    const year = String(date.getUTCFullYear()); // Get last two digits of the year

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  const formatDateAndTime = (dateData) => {
    const date = new Date(dateData);

    // Convert to IST (GMT +5:30)
    date.setMinutes(date.getMinutes() + 330); // 5 hours 30 minutes

    const day = String(date.getUTCDate()).padStart(2, '0'); // Ensure two digits
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Ensure two digits
    const year = String(date.getUTCFullYear()); // Get last two digits of the year

    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, '0'); // Ensure two digits
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight

    const formattedDateAndTime = `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;

    return formattedDateAndTime;
  }


  const updateFollowup = async () => {
    if (flwupMessage.trim().length > 0) {
      try {
        const response = await axios.post(backUrl + 'flwup/updateFollowup', {
          resultId: id,
          message: flwupMessage,
        }, { headers });

        getAllFollowup(id);
        setFlwupMessage("");
      } catch (error) {
        console.log(error);
      }
    } else {
      setErrorFlwup("Please enter followup message");
    }
  }

  const updateStatus = async (stat) => {
    if (status !== stat) {
      try {
        await axios.post(backUrl + 'flwup/updateStatus', {
          resultId: id,
          status: stat,
        }, { headers });

        getAllFollowup(id);
        setStatus("");
        getSingleDetails(id);
      } catch (error) {
        console.log(error);
      }
    }
  }

  const sendMailNow = async () => {
    console.log("sendmail clicked..");
    try {
      const response = await axios.post(backUrl + 'data/sendMailNow', {
        mailId: resultData.email,
        toEmail: resultData.email,
        toName: resultData.name,
        subject: subject,
        content: content,
        date: date,
        time: time,
      }, { headers });

      console.log(response.data);
      if (response.status === 200) {
        setContent("");
        setSubject("");
        setDate("");
        setTime("");
        getAllMailByTo(resultData.email);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllMailByTo = async (mailId) => {
    try {
      if (mailId === "Not found") {
        setMailResult([]);
      } else {
        const response = await axios.post(backUrl + 'data/getAllMailByTo', { mailId }, { headers });

        setMailResult(response.data.transactionalEmails);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getAllFollowup = async (id) => {
    try {
      const response = await axios.post(backUrl + 'flwup/getAllFollowup', { resultId: id }, { headers });

      setFollowupData(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const updateEmail = async () => {
    try {
      await axios.post(backUrl + 'flwup/updateEmail', { id: id, email: emailEdit }, { headers });

      setShowEmailEdit(false);
      getSingleDetails(id);
      getAllMailByTo(resultData.email);
      getAllFollowup(id);
    } catch (error) {
      console.log(error);
    }
  }

  const updateWebsite = async () => {
    try {
      await axios.post(backUrl + 'flwup/updateWebsite', { id: id, website: websiteEdit }, { headers });

      setShowWebsiteEdit(false);
      getSingleDetails(id);
      getAllMailByTo(resultData.email);
      getAllFollowup(id);
    } catch (error) {
      console.log(error);
    }
  }

  const updatePhone = async () => {
    try {
      await axios.post(backUrl + 'flwup/updatePhone', { id: id, phone: numberEdit }, { headers });

      setShowNumberEdit(false);
      getSingleDetails(id);
      getAllMailByTo(resultData.email);
      getAllFollowup(id);
    } catch (error) {
      console.log(error);
    }
  }

  const updateAddress = async () => {
    try {
      await axios.post(backUrl + 'flwup/updateAddress', { id: id, address: addressEdit }, { headers });

      setShowAddressEdit(false);
      getSingleDetails(id);
      getAllMailByTo(resultData.email);
      getAllFollowup(id);
    } catch (error) {
      console.log(error);
    }
  }

  const updateExpectedRevenue = async () => {
    try {
      await axios.post(backUrl + 'flwup/updateExpectedRevenue', { id: id, expectedRevenue: expectedRevenueEdit }, { headers });

      setShowExpectedRevenueEdit(false);
      getSingleDetails(id);
      getAllMailByTo(resultData.email);
      getAllFollowup(id);
    } catch (error) {
      console.log(error);
    }
  }

  const updateProbability = async () => {
    try {
      await axios.post(backUrl + 'flwup/updateProbability', { id: id, probability: probabilityEdit }, { headers });

      setShowProbabilityEdit(false);
      getSingleDetails(id);
      getAllMailByTo(resultData.email);
      getAllFollowup(id);
    } catch (error) {
      console.log(error);
    }
  }

  const updateExpectedClosing = async () => {
    try {
      await axios.post(backUrl + 'flwup/updateExpectedClosing', { id: id, expectedClosing: expectedClosingEdit }, { headers });

      setShowExpectedClosingEdit(false);
      getSingleDetails(id);
      getAllMailByTo(resultData.email);
      getAllFollowup(id);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getSingleDetails(id);
    getAllFollowup(id);
  }, []);

  return (
    <div className='container-fluid'>

      {/* This is folloup modal */}
      <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content stylish_modal">
            <div class="modal-header modal_header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setFlwupMessage(""); setErrorFlwup("") }}></button>
            </div>
            <div class="modal-body">
              <div className='container-fluid'>
                <div className='row'>
                  <h6 className='input_label_followup'>Enter Followup Message</h6>
                  <input className='followup_input' type="text" value={flwupMessage} onChange={(e) => setFlwupMessage(e.target.value)} />
                </div>
              </div>
            </div>
            <div class="modal-footer modal_footer">
              <button type="button" class="close_button_followup" data-bs-dismiss="modal" onClick={() => { setFlwupMessage(""); setErrorFlwup("") }}>Close</button>
              {flwupMessage.trim().length > 0 && (
                <button type="button" class="submit_button_followup" data-bs-dismiss="modal" onClick={updateFollowup}>Submit</button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* This is send mail modal */}
      <div class="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdrop2Label" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content stylish_modal">
            <div class="modal-header modal_header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setFlwupMessage(""); setErrorFlwup("") }}></button>
            </div>
            <div class="modal-body">
              <div className='container-fluid'>
                <div className="row">
                  <h5 className='send_mail_heading'>SEND MAIL</h5>
                  <div className='col-lg-12 pb-3'>
                    <p className='text-white fw-bold mx-1 send_mail_subject'>Subject</p>
                    <input type='text' name='subject' class="form-control" placeholder='Subject' value={subject} onChange={(e) => setSubject(e.target.value)} />
                  </div>
                  {/* <div className='col-lg-6 pb-3'>
                                <p className='text-white fw-bold mx-1'>Schedule Date & Time</p>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <input type='date' name='schedule_date' class="form-control" value={date} onChange={(e) => setDate(e.target.value)} />
                                    </div>
                                    <div className='col-lg-6'>
                                        <input type='time' name='schedule_time' class="form-control" value={time} onChange={(e) => setTime(e.target.value)} />
                                    </div>
                                </div>
                            </div> */}
                  <JoditEditor

                    ref={editor}
                    value={content}
                    onChange={newContent => setContent(newContent)}
                  />

                  <div class="modal-footer modal_footer">
                    <button type="button" class="close_button_followup" data-bs-dismiss="modal">Close</button>
                    {subject.trim().length > 0 && (
                      <button type="button" class="submit_button_followup" data-bs-dismiss="modal" onClick={sendMailNow}>Send</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mt-3'>
        <div className="container-fluid">
          <div className="wrapper">
            <div className="arrow-steps clearfix">
              {resultData.stage === "NEW" ? (
                <div className="step current"> <span>NEW</span> </div>
              ) : (
                <div className="step" onClick={() => updateStatus("NEW")}> <span>NEW</span> </div>
              )}

              {resultData.stage === "RESPONDED" ? (
                <div className="step current"> <span>RESPONDED</span> </div>
              ) : (
                <div className="step" onClick={() => updateStatus("RESPONDED")}> <span>RESPONDED</span> </div>
              )}

              {resultData.stage === "CONFIRMED" ? (
                <div className="step current"> <span>CONFIRMED</span> </div>
              ) : (
                <div className="step" onClick={() => updateStatus("CONFIRMED")}> <span>CONFIRMED</span> </div>
              )}

              {resultData.stage === "WON" ? (
                <div className="step current"> <span>WON</span> </div>
              ) : (
                <div className="step" onClick={() => updateStatus("WON")}> <span>WON</span> </div>
              )}

              {resultData.stage === "LOST" ? (
                <div className="step current"> <span>LOST</span> </div>
              ) : (
                <div className="step" onClick={() => updateStatus("LOST")}> <span>LOST</span> </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-lg-5'>
          <div className='row ps-1 mt-2'>
            <div className='col-lg-4 info_text_followup'>Name</div>
            <div className='col-lg-8 info_text_followup'>{resultData.name}</div>

            <div className='col-lg-4 info_text_followup'>Email</div>
            <div className='col-lg-8 info_text_followup'>
              {showEmailEdit ? (
                <>
                  <input className='edit_input_followup' type="text" value={emailEdit} onChange={(e) => setEmailEdit(e.target.value)} />
                  <span className='ms-1 edit_button_followup' onClick={updateEmail}><BiSave /></span>
                  <span className='ms-1 edit_button_followup' onClick={() => setShowEmailEdit(false)}><RxCross2 /></span>
                </>
              ) : (
                <>
                  {resultData.email}
                  <span className='ms-1 edit_button_followup' onClick={() => setShowEmailEdit(true)}><FiEdit /></span>
                </>
              )}
            </div>

            <div className='col-lg-4 info_text_followup'>Website</div>
            <div className='col-lg-8 info_text_followup'>
              {showWebsiteEdit ? (
                <>
                  <input className='edit_input_followup' type="text" value={websiteEdit} onChange={(e) => setWebsiteEdit(e.target.value)} />
                  <span className='ms-1 edit_button_followup' onClick={updateWebsite}><BiSave /></span>
                  <span className='ms-1 edit_button_followup' onClick={() => setShowWebsiteEdit(false)}><RxCross2 /></span>
                </>
              ) : (
                <>
                  {resultData.website}
                  <span className='ms-1 edit_button_followup' onClick={() => setShowWebsiteEdit(true)}><FiEdit /></span>
                </>
              )}
            </div>

            <div className='col-lg-4 info_text_followup'>Phone Number</div>
            <div className='col-lg-8 info_text_followup'>
              {showNumberEdit ? (
                <>
                  <input className='edit_input_followup' type="text" value={numberEdit} onChange={(e) => setNumberEdit(e.target.value)} />
                  <span className='ms-1 edit_button_followup' onClick={updatePhone}><BiSave /></span>
                  <span className='ms-1 edit_button_followup' onClick={() => setShowNumberEdit(false)}><RxCross2 /></span>
                </>
              ) : (
                <>
                  {resultData.phone_number}
                  <span className='ms-1 edit_button_followup' onClick={() => setShowNumberEdit(true)}><FiEdit /></span>
                </>
              )}
            </div>

            <div className='col-lg-4 info_text_followup'>Address</div>
            <div className='col-lg-8 info_text_followup'>
              {showAddressEdit ? (
                <>
                  <input className='edit_input_followup' type="text" value={addressEdit} onChange={(e) => setAddressEdit(e.target.value)} />
                  <span className='ms-1 edit_button_followup' onClick={updateAddress}><BiSave /></span>
                  <span className='ms-1 edit_button_followup' onClick={() => setShowAddressEdit(false)}><RxCross2 /></span>
                </>
              ) : (
                <>
                  {resultData.address}
                  <span className='ms-1 edit_button_followup' onClick={() => setShowAddressEdit(true)}><FiEdit /></span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className='col-lg-5'>
          <div className='row ps-1 mt-2'>
            <div className='col-lg-4 info_text_followup'>Sales Person</div>
            <div className='col-lg-8 info_text_followup'>Sanjeet Kumar Mishra</div>

            <div className='col-lg-4 info_text_followup'>Expected Revenue</div>
            <div className='col-lg-8 info_text_followup'>
              {showExpectedRevenueEdit ? (
                <>
                  <input className='edit_input_followup' type="number" value={expectedRevenueEdit} onChange={(e) => setExpectedRevenueEdit(e.target.value)} />
                  <span className='ms-1 edit_button_followup' onClick={updateExpectedRevenue}><BiSave /></span>
                  <span className='ms-1 edit_button_followup' onClick={() => setShowExpectedRevenueEdit(false)}><RxCross2 /></span>
                </>
              ) : (
                <>
                  &#8377; {resultData.expected_revenue}
                  <span className='ms-1 edit_button_followup' onClick={() => setShowExpectedRevenueEdit(true)}><FiEdit /></span>
                </>
              )}
            </div>

            <div className='col-lg-4 info_text_followup'>Probability</div>
            <div className='col-lg-8 info_text_followup'>
              {showProbabilityEdit ? (
                <>
                  <input className='edit_input_followup' type="number" value={probabilityEdit} onChange={(e) => setProbabilityEdit(e.target.value)} />
                  <span className='ms-1 edit_button_followup' onClick={updateProbability}><BiSave /></span>
                  <span className='ms-1 edit_button_followup' onClick={() => setShowProbabilityEdit(false)}><RxCross2 /></span>
                </>
              ) : (
                <>
                  {resultData.probability + "%"}
                  <span className='ms-1 edit_button_followup' onClick={() => setShowProbabilityEdit(true)}><FiEdit /></span>
                </>
              )}
            </div>

            <div className='col-lg-4 info_text_followup'>Expected Closing</div>
            <div className='col-lg-8 info_text_followup'>
              {showExpectedClosingEdit ? (
                <>
                  <input className='edit_input_followup' type="date" value={expectedClosingEdit} onChange={(e) => setExpectedClosingEdit(e.target.value)} />
                  <span className='ms-1 edit_button_followup' onClick={updateExpectedClosing}><BiSave /></span>
                  <span className='ms-1 edit_button_followup' onClick={() => setShowExpectedClosingEdit(false)}><RxCross2 /></span>
                </>
              ) : (
                <>
                  {formatDate(resultData.expected_closing)}
                  <span className='ms-1 edit_button_followup' onClick={() => setShowExpectedClosingEdit(true)}><FiEdit /></span>
                </>
              )}
            </div>

            <div className='col-lg-4 info_text_followup'>Last Update</div>
            <div className='col-lg-8 info_text_followup'>{formatDate(resultData.updatedAt)}</div>
          </div>
        </div>

        <div className='col-lg-2'>
          <div className='row ps-1 mt-2'>
            <button className='followup_button mt-3' data-bs-toggle="modal" data-bs-target="#staticBackdrop">FOLLOW UP</button>
            <button className='followup_button mt-3'><a href={resultData.website} target="_blank" rel="noopener noreferrer" className='hover_color_sendMail'>VISIT WEBSITE</a></button>
            <button className='followup_button mt-3' data-bs-toggle="modal" data-bs-target="#staticBackdrop2">SEND MAIL</button>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-lg-6'>
          <div className='row ps-1 mt-4'>
            <h6 className='text-center mail_history_heading'>MAIL HISTORY</h6>
          </div>
          {Array.isArray(mailResult) && mailResult.length > 0 ? (
            <div className='upper_history_row'>
              {
                mailResult.map((item, index) => (
                  <div className='row ps-1 mt-2 history_row' key={index}>
                    <div className='col-12'>
                      <h5 className='mail_subject'>{item.subject}</h5>
                    </div>
                    <div className='col-12'>
                      <h5 className='mail_date text-end'>{formatDateAndTime(item.date)}</h5>
                    </div>
                  </div>
                ))
              }
            </div>
          ) : (
            <div className='row ps-1 mt-5'>
              <h6 className='text-center normal_activity_message'>No activities found</h6>
            </div>
          )}
        </div>

        <div className='col-lg-6'>
          <div className='row ps-1 mt-4'>
            <h6 className='text-center mail_history_heading'>FOLLOW UP HISTORY</h6>
          </div>
          {followupData.length > 0 ? (
            <div className='upper_history_row'>
              {
                followupData.map((item, index) => (
                  <div className='row ps-1 mt-2 history_row' key={index}>
                    <div className='col-12'>
                      <h5 className='mail_subject'>{item.message}</h5>
                    </div>
                    <div className='col-12'>
                      <h5 className='mail_date text-end'>{formatDateAndTime(item.createdAt)}</h5>
                    </div>
                  </div>
                ))
              }
            </div>
          ) : (
            <div className='row ps-1 mt-5'>
              <h6 className='text-center normal_activity_message'>No activities found</h6>
            </div>
          )}
        </div>
      </div>

    </div>
  )
}

export default Followup