import React from 'react';
import './Home.css';
import { CgArrowLongRight } from "react-icons/cg";

const whatWeDoData = [
    {
        photo: "https://wp.themepure.net/seomy/wp-content/uploads/2023/09/choose-icon-1.png",
        title: "Experts Consulting",
    },
    {
        photo: "https://wp.themepure.net/seomy/wp-content/uploads/2023/09/choose-icon-2.png",
        title: "Competitor Analysis",
    },
    {
        photo: "https://wp.themepure.net/seomy/wp-content/uploads/2023/09/choose-icon-3.png",
        title: "Responsive Support",
    },
]

const WhatWeDo = () => {
    return (
        <div className='container-fluid py-5' id='about'>
            <div className='row py-5'>
                <div className='col-lg-6 d-flex justify-content-center photo_part_div' style={{ backgroundImage: 'url(/Images/back2.png)' }}>
                    <img src="/Images/choose-2.jpg" className='moving_photo_landing_page' />
                </div>
                <div className='col-lg-6 details_part_whatwedo'>
                    <div className='row'>
                        <div className='tpbanner__sub-title mb-15'>
                            <span class="tp-el-subtitle">Why Choose us</span>
                            <i>
                                <svg width="130" height="42" viewBox="0 0 130 42" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <rect y="0.941895" width="130" height="40.9551" fill="url(#pattern6)" fillOpacity="0.08" />
                                    <defs>
                                        <pattern id="pattern6" patternContentUnits="objectBoundingBox" width="1" height="1">
                                            <use xlinkHref="#image0_868_3547" transform="translate(-0.0587762 0.0123052) scale(0.00611916 0.0198269)" />
                                        </pattern>
                                        <image
                                            id="image0_868_3547"
                                            width="180"
                                            height="50"
                                            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAAAAyCAYAAAD1JPH3AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDcuMS1jMDAwIDc5LmVkYTJiM2ZhYywgMjAyMS8xMS8xNy0xNzoyMzoxOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RUMyMzkwQTczMTM3MTFFRDg3NUZBOUZCRDk1MThFMTMiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RUMyMzkwQTYzMTM3MTFFRDg3NUZBOUZCRDk1MThFMTMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIzLjEgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MjFBQkIwMjIzMTA2MTFFREEzRkNBQzVFOTRFRTVERTgiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MjFBQkIwMjMzMTA2MTFFREEzRkNBQzVFOTRFRTVERTgiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5lgZ3iAAAB2ElEQVR42uzd7U3CUBiA0Wr4DxvQDWQE4gS6gSO6ibhB2aBMoCG5ja83pVRaSSnnJE2/MII+t7386sPz21dxQ1ZpKdNSpPUynItL/nNd6rSc2t6n/Sot+TYTsJhgrJsU6TqLt7zS779EE3ad1vuwvwuDg5kF3QQao92E47fq3ICrQ/RN8LsQPBMPOob7lEV8j5o7z6Yj+Cbwzyx+U5orB32MdCvcwcFv0/ZLx5SmubrHAVCZ0lwW9CrEu07rcsC8k/GmNPk8vg7hx2P13K/4fYP+6LhdcjvR5wMgrmP0h5bXxdecmjadu1N0ffFuOxc/zzKcP65fhwQt5nkOgGJu08NH/1cEDYIGQYOgETQIGgQNggZBI2gQNAgaBA2CRtAgaBA0CBoEjaBB0CBoEDQIGkGDoEHQIGgQNIIGQYOgQdAgaAQNE7aY+Ptre7JSn6ct9VWe2UfQvx4XFuPbZ+fz1/5HsGPEHh831mw359bhWH6OCQf9Xvw8v67OQmyLcg6GfJ4Yd769Dvviv+yuXQ0N+tXf8aKpUtUz/rJlvWw5Pmf5RfIQ7vJV0fO55wvtTSL+3R+mQasTd4BlyzRpdeWBEKeL+aC+yt1d0PczDSpH/PJbjfi+RvUtwADssXzgKiX3gwAAAABJRU5ErkJggg=="
                                        />
                                    </defs>
                                </svg>
                            </i>
                        </div>
                    </div>
                    <div className='row pt-2'>
                        <h1 className='what_we_do_heading'>What makes Us Different from Others</h1>
                    </div>
                    <div className='row'>
                        <p className='what_we_do_desc'>Migrating your rank tracking tool is like migrating from Google Analytics to another solution.</p>
                    </div>

                    <div className='row gx-3 card_row_whatwedo'>
                        {whatWeDoData.map((item, index) => (
                            <div class="col-lg-4 col-md-4 col-sm-6" key={index}>
                                <div class="tpchoose mb-30 card_what_we_do">
                                    <div class="tpchoose-icon mb-25">
                                        <span class="tp-el-rep-icon">
                                            <img decoding="async" src={item.photo} alt="" />
                                        </span>
                                    </div>
                                    <div class="tpchoose-text">
                                        <span class="tpchoose-title tp-el-rep-title">{item.title}</span>
                                        <div class="tparrow-right">
                                            <CgArrowLongRight />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default WhatWeDo