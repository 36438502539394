import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { backUrl } from '../../constants';
import { useSelector } from 'react-redux';
import { convertISOToCustomFormat } from '../../Utility/formatDate';
import { TbReload } from "react-icons/tb";
import { FaRegStar, FaChevronLeft, FaChevronRight, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';

const DraftsMail = () => {
    const navigate = useNavigate();
    const { token } = useSelector(state => state.auth);
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    }

    const [drafts, setDrafts] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [totalMail, setTotalMail] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const getDrafts = async () => {
        try {
            const response = await axios.post(`${backUrl}mail/getalldrafts`, { startDate, endDate }, { headers });
            setDrafts(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage < numberOfPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const reloadMailData = () => {
        getDrafts();
    }

    const deleteDraft = async (id) => {
        try {
            await axios.post(`${backUrl}mail/deletedraft`, { id }, { headers });
            getDrafts();
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (startDate && endDate && startDate <= endDate) {
            getDrafts();
        } else if (!startDate && !endDate) {
            getDrafts();
        } else if (startDate > endDate) {
            alert('Start date should be less than end date');
        }
    }, [startDate, endDate]);

    useEffect(() => {
        getDrafts();
    }, []);
 
    const startIdx = (currentPage - 1) * 10;
    const endIdx = startIdx + 10;
    const currentEmails = drafts.slice(startIdx, endIdx);

    return (
        <div className='container-fluid'>
            <div className='row'>
                <h5 className='text-center my-3 all_results_heading'>DRAFT MAIL</h5>
            </div>

            <div className="row header-row align-items-center">
                <div className="col-1 tick-all-container">
                    <input type="checkbox" className="tick-all" />
                </div>
                <div className="col-1 d-flex justify-content-center">
                    <span className="reload" onClick={reloadMailData}><TbReload /></span>
                </div>
                <div className="col-2">
                    <input type='date' className='date_input_send_mail' name='start_date' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                </div>
                <div className="col-1 text-center">
                    To
                </div>
                <div className="col-2">
                    <input type='date' className='date_input_send_mail' name='end_date' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>
                <div className="col-2 pagination-result text-end">
                    <span>{startIdx + 1}-{Math.min(endIdx, totalMail)} of {totalMail}</span>
                </div>
                <div className='col-1'>
                    <div className='row'>
                        <div className="col-6 pagination-info" onClick={previousPage}>
                            <FaChevronLeft />
                        </div>
                        <div className="col-6 pagination-info" onClick={nextPage}>
                            <FaChevronRight />
                        </div>
                    </div>
                </div>
                <div className='col-2'>
                    <Link to="/dashboard/sendmail">
                        <button className='new_mail_button_send_mail'>New Mail</button>
                    </Link>
                </div>
            </div>

            <div className='row'>
                <div className="col-12">
                    {currentEmails.map(item => (
                        <div className="mail-item row align-items-center" key={item._id}>
                            <div className="col-1 tick-all-container">
                                <input type="checkbox" className="tick-all" />
                            </div>
                            <div className="col-1 d-flex justify-content-center">
                                <button className='edit_draft_button' onClick={()=>navigate(`/dashboard/newmail/${item._id}`)}>Edit</button>
                            </div>
                            <div className="col-3 subject">
                                {item.subject}
                            </div>
                            <div className="col-4 content">
                                {item.toEmail}
                            </div>
                            <div className="col-2 time">
                                {convertISOToCustomFormat(item.updatedAt)}
                            </div>
                            <div className="col-1 d-flex justify-content-end">
                                <span className="delete_draft_icon" onClick={() => deleteDraft(item._id)}><FaTrash /></span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default DraftsMail