import React, { useEffect, useState } from 'react';
import { HiMiniUserGroup } from "react-icons/hi2";
import { FaCircle } from "react-icons/fa";
import axios from 'axios';
import { backUrl } from '../../constants';
import { formatDate, convertISOToCustomFormat } from '../../Utility/formatDate';
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { fetchTeamDetails, findMemberByEmail, addUser, fetchTeamActivity } from '../../Reducers/teamDataSlice';
// import { fetchTeamActivity } from '../../Reducers/teamActivitySlice';

const teamKaData = JSON.parse(localStorage.getItem("teamData"));

const TeamManage = () => {
    const dispatch = useDispatch();

    const token = useSelector(state => state.auth.token);
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };

    const { teamData } = useSelector(state => state.team);
    const { team, teamActivity } = useSelector(state => state.teamData);
    // const { activity } = useSelector(state => state.teamActivity);
    const result = useSelector(state => state.teamData);
    console.log('result', result);

    const [email, setEmail] = useState("");
    const [searchedMember, setSearchedMember] = useState("");
    const [noUserError, setNoUserError] = useState(false);
    const [selectedMember, setSelectedMember] = useState("");
    const [userData, setUserData] = useState("");
    const [eligibleToRemoveUser, setEligibleToRemoveUser] = useState(false);
    const [eligibleToMakeAdmin, setEligibleToMakeAdmin] = useState(false);

    const handleFindMember = async () => {
        try {
            dispatch(findMemberByEmail({ email, teamid: teamData[0].teamid, headers }));
        } catch (error) {
            console.log(error);
        }
    }

    const clearSearchData = () => {
        dispatch(clearSearchData());
        setEmail("");
        setSearchedMember("");
    }

    const addAsUser = async () => {
        try {
            await axios.post(backUrl + "team/adduser", { teamId: teamKaData.teamid, userid: searchedMember.userId }, { headers });
            dispatch(fetchTeamDetails({ teamid: teamKaData.teamid }));
        } catch (error) {
            console.log(error);
            if (error.response.status === 400) {
                alert(error.response.data.message);
            }
        }
        dispatch(clearSearchData());
        setEmail("");
        setSearchedMember("");
    }

    const addAsAdmin = async () => {
        try {
            await axios.post(backUrl + "team/addadmin", { teamId: teamKaData.teamid, userid: searchedMember.userId }, { headers });
            dispatch(fetchTeamDetails({ teamid: teamKaData.teamid }));
        } catch (error) {
            console.log(error);
            if (error.response.status === 400) {
                alert(error.response.data.message);
            }
        }
        dispatch(clearSearchData());
        setEmail("");
        setSearchedMember("");
    }

    const makeAdmin = async () => {
        try {
            await axios.post(backUrl + "team/makeadmin", { teamid: teamKaData.teamid, userid: selectedMember.userid }, { headers });
            dispatch(fetchTeamDetails({ teamid: teamKaData.teamid }));
        } catch (error) {
            console.log(error);
            if (error.response.status === 400) {
                alert(error.response.data.message);
            }
        }
        dispatch(clearSearchData());
        setEmail("");
        setSearchedMember("");
    }

    const removeUser = async () => {
        try {
            await axios.post(backUrl + "team/removemember", { teamid: teamKaData.teamid, userid: selectedMember.userid }, { headers });
            dispatch(fetchTeamDetails({ teamid: teamKaData.teamid }));
        } catch (error) {
            console.log(error);
            if (error.response.status === 400) {
                alert(error.response.data.message);
            }
        }
    }

    const checkPermission = (selected, user, team) => {
        // console.log(selected.userid, selected.role, user.userId, teamKaData.role);
        try {
            if (selected.userid === user.userId) {
                setEligibleToMakeAdmin(false);
                setEligibleToRemoveUser(false);
            } else {
                if (teamKaData.role === "Owner") {
                    if (selected.role === "User") {
                        setEligibleToMakeAdmin(true);
                        setEligibleToRemoveUser(true);
                    } else {
                        setEligibleToMakeAdmin(false);
                        setEligibleToRemoveUser(true);
                    }
                } else if (teamKaData.role === "Admin") {
                    if (selected.role === "User") {
                        setEligibleToMakeAdmin(false);
                        setEligibleToRemoveUser(true);
                    } else {
                        setEligibleToMakeAdmin(false);
                        setEligibleToRemoveUser(false);
                    }
                } else {
                    setEligibleToMakeAdmin(false);
                    setEligibleToRemoveUser(false);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (teamData) {
            dispatch(fetchTeamDetails({ teamid: teamData[0].teamid, headers }));
            dispatch(fetchTeamActivity({ teamid: teamData[0].teamid, headers }));
        }
    }, [teamData]);

    console.log('team', teamData);
    console.log('teamActivity', teamActivity);
    return (
        <div className='row mt-2'>
            <div className='col'>
                <div className='row align-items-center team_heading_row'>
                    <div className='col-lg-4 d-flex align-items-center'>
                        <div className='left_side_team_manage'><span className='pe-2 fs-1'><HiMiniUserGroup /></span><span className='fs-2 mt-1 fw-bold'>{team.length > 0 ? team[0].teamdetails[0].teamName : "Loading..."}</span></div>
                    </div>
                    <div className='col-lg-8 d-flex justify-content-end align-items-end'>
                        {result.team && result.team[0]?.role === 'Owner' && <button className='add_member_button' data-bs-toggle="modal" data-bs-target="#staticBackdrop">Add Member</button>}
                        {result.team && result.team[0]?.role === 'Admin' && <button className='add_member_button' data-bs-toggle="modal" data-bs-target="#staticBackdrop">Add Member</button>}
                    </div>
                </div>

                <div className='row gx-2 gy-2'>
                    <div className='col-lg-4'>
                        <div className='team_details_cols container-fluid'>
                            <div className='row basic_details_heading_manage_team'>
                                BASIC DETAILS
                            </div>
                            <div className='row fix_height_row_manage_team'>
                                <div className='col'>
                                    <div className='row pt-2'>
                                        <div className='col-4 td1k_manage_team'>
                                            Team Owner
                                        </div>
                                        <div className='col-8 td1v_manage_team'>
                                            {team.length > 0 && team[0]._id ? team[0].createdByDetails[0].fullname : "Loading..."}
                                        </div>
                                    </div>
                                    <div className='row pt-2'>
                                        <div className='col-4 td1k_manage_team'>
                                            Members
                                        </div>
                                        <div className='col-8 td1v_manage_team'>
                                            {team.length > 0 && team[0]._id ? team[0].members.length : "Loading..."}
                                        </div>
                                    </div>
                                    <div className='row pt-2'>
                                        <div className='col-4 td1k_manage_team'>
                                            Credits
                                        </div>
                                        <div className='col-8 td1v_manage_team'>
                                            3560
                                        </div>
                                    </div>
                                    <div className='row pt-2'>
                                        <div className='col-4 td1k_manage_team'>
                                            Plan
                                        </div>
                                        <div className='col-8 td1v_manage_team'>
                                            Standard
                                        </div>
                                    </div>
                                    <div className='row pt-2'>
                                        <div className='col-4 td1k_manage_team'>
                                            Next Renewal
                                        </div>
                                        <div className='col-8 td1v_manage_team'>
                                            22nd Jan 2024
                                        </div>
                                    </div>
                                    <div className='row pt-2'>
                                        <div className='col-4 td1k_manage_team'>
                                            Last Renewal
                                        </div>
                                        <div className='col-8 td1v_manage_team'>
                                            22nd Dec 2023
                                        </div>
                                    </div>
                                    <div className='row pt-2 pb-2'>
                                        <div className='col-4 td1k_manage_team'>
                                            Creation Date
                                        </div>
                                        <div className='col-8 td1v_manage_team'>
                                            {team.length > 0 && team[0]._id ? formatDate(team[0].createdAt) : "Loading..."}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='team_details_cols container-fluid'>
                            <div className='row basic_details_heading_manage_team'>
                                TEAM MEMBERS
                            </div>
                            <div className='row fix_height_row_manage_team'>
                                <div className='col'>
                                    {team && team[0]?.members && team[0].members.map(item => (
                                        <div className='row member_card_team mt-1' key={item.userid} onClick={() => { setSelectedMember(item); checkPermission(item, userData, teamKaData) }} data-bs-toggle="modal" data-bs-target="#staticBackdrop2">
                                            <div className='col-9'>
                                                <div className='row member_name_manage_team'>
                                                    {item.fullname}
                                                </div>
                                                <div className='row member_role_manage_team'>
                                                    Team {item.role}
                                                </div>
                                            </div>
                                            {item.isOnline === true ? (
                                                <div className='col-3 member_status_manage_team'>
                                                    <div className='row member_status_active'>
                                                        <FaCircle />
                                                    </div>
                                                    <div className='row member_status_online'>
                                                        Online
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className='col-3 member_status_manage_team'>
                                                    <div className='row member_status_inactive'>
                                                        <FaCircle />
                                                    </div>
                                                    <div className='row member_status_offline'>
                                                        Offline
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='team_details_cols container-fluid'>
                            <div className='row basic_details_heading_manage_team'>
                                TEAM ACTIVITY
                            </div>
                            <div className='row fix_height_row_manage_team'>
                                <div className='col-12'>
                                    {teamActivity && teamActivity.map(item => (
                                        <div className='row d-flex align-items-start activity_list' key={item._id}>
                                            {/* <div className='col-1 activity_time_icon'>
                                                <MdOutlineAccessTimeFilled />
                                            </div> */}
                                            <div className='col-12 activity_details_total'>
                                                <div className='row'>
                                                    <div className='col-12 activity_time_team'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                {convertISOToCustomFormat(item.createdAt)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 activity_details_team'>
                                                        {item.activityType === 1 && (
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <span className='name_style_activity'>{item.involveduserdetails[0].fullname}</span> added <span className='name_style_activity'>{item.targetuserdetails[0].fullname}</span> as user
                                                                </div>
                                                            </div>
                                                        )}
                                                        {item.activityType === 2 && (
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <span className='name_style_activity'>{item.involveduserdetails[0].fullname}</span> removed <span className='name_style_activity'>{item.targetuserdetails[0].fullname}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {item.activityType === 3 && (
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <span className='name_style_activity'>{item.involveduserdetails[0].fullname}</span> added <span className='name_style_activity'>{item.targetuserdetails[0].fullname}</span> as admin
                                                                </div>
                                                            </div>
                                                        )}
                                                        {item.activityType === 4 && (
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    <span className='name_style_activity'>{item.involveduserdetails[0].fullname}</span> made <span className='name_style_activity'>{item.targetuserdetails[0].fullname}</span> admin
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Add Members Modal */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content modal_content_add_member">
                        <div className="modal-header modal_header_add_member">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Add Member</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearSearchData}></button>
                        </div>
                        <div className="modal-body modal_body_add_member">
                            <div className='row enter_mail_label'>
                                Enter mail address
                            </div>
                            <div className='row add_member_input_div'>
                                <div className='col-10 d-flex justify-content-center align-items-center'>
                                    <input className='add_member_input' placeholder='abc@mail.com' value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className='col-2 search_add_member' type="button" onClick={handleFindMember}>
                                    Search
                                </div>
                            </div>

                            {result.searchedMember !== "no data found" && result.searchedMember !== "" && (
                                <div className='row member_card_team2 mt-2'>
                                    <div className='col-9'>
                                        <div className='row member_name_manage_team'>
                                            {result.searchedMember.name}
                                        </div>
                                        {result.searchedMember.role ? (
                                            <div className='row member_role_manage_team'>
                                                Team {result.searchedMember.role}
                                            </div>
                                        ) : (
                                            <div className='row member_role_manage_team'>
                                                Not in team
                                            </div>
                                        )}
                                    </div>
                                    {result.searchedMember.isOnline === true ? (
                                        <div className='col-3 member_status_manage_team'>
                                            <div className='row member_status_active'>
                                                <FaCircle />
                                            </div>
                                            <div className='row member_status_online'>
                                                Online
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='col-3 member_status_manage_team'>
                                            <div className='row member_status_inactive'>
                                                <FaCircle />
                                            </div>
                                            <div className='row member_status_offline'>
                                                Offline
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {result.searchedMember === "no data found" && (
                                <div className='row text-danger'>
                                    {!result.searchedMember.name && (
                                        <div className='text-center pt-3'>
                                            No User Found
                                        </div>
                                    )}
                                </div>
                            )}

                        </div>
                        <div className="modal-footer modal_footer_add_member">
                            {result.searchedMember.name && (
                                <>
                                    {!result.searchedMember.role && (
                                        <button className='add_to_team_button' data-bs-dismiss="modal" aria-label="Close" onClick={addAsUser}>Add as user</button>
                                    )}
                                    {!result.searchedMember.role && (
                                        <button className='cancel_team' data-bs-dismiss="modal" aria-label="Close" onClick={addAsAdmin}>Add as admin</button>
                                    )}
                                </>
                            )}

                            <button className='cancel_team' data-bs-dismiss="modal" aria-label="Close" onClick={clearSearchData}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Manage user modal */}
            <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content modal_content_add_member">
                        <div className="modal-header modal_header_add_member">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Manage User</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setSelectedMember("")}></button>
                        </div>
                        <div className="modal-body modal_body_add_member">
                            <div className='row'>
                                <div className='col-3 fw-bold'>
                                    Name
                                </div>
                                <div className='col-9'>
                                    {result.selectedMember ? result.selectedMember.userdetails[0].fullname : ""}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-3 fw-bold'>
                                    Role
                                </div>
                                <div className='col-9'>
                                    {result.selectedMember ? result.selectedMember.role : ""}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-3 fw-bold'>
                                    Joined On
                                </div>
                                <div className='col-9'>
                                    {result.selectedMember ? formatDate(result.selectedMember.createdAt) : ""}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer modal_footer_add_member">
                            {eligibleToRemoveUser && (
                                <button className='add_to_team_button' data-bs-dismiss="modal" aria-label="Close" onClick={removeUser}>Remove User</button>
                            )}
                            {eligibleToMakeAdmin && (
                                <button className='cancel_team' data-bs-dismiss="modal" aria-label="Close" onClick={makeAdmin}>Make Admin</button>
                            )}
                            <button className='cancel_team' data-bs-dismiss="modal" aria-label="Close" onClick={() => setSelectedMember("")}>Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TeamManage