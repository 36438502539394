import React from 'react';
import './Home.css';
import { MdBusinessCenter } from "react-icons/md";
import { FaDatabase } from "react-icons/fa";
import { MdLeaderboard } from "react-icons/md";

const serviceData = [
    {
        heading: "Lead Management",
        logo: <MdLeaderboard />,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    },
    {
        heading: "Data Scraping",
        logo: <FaDatabase />,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    },
    {
        heading: "Business Tools",
        logo: <MdBusinessCenter />,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    },
]

const Services = () => {
    return (
        <div className='container-fluid py-5 service_container' id='services'>
            <div className='row services_heading pb-2 pt-3'>
                Our Services
            </div>
            <p className='text-center services_subheading'>
                Enabling Your Business Expansion by Facilitating Lead Management,
                Precise Data Extraction,
                and Cutting-Edge Business Tool Integration for Heightened Productivity and Substantial Achievements
            </p>
            <div className='row px-3 d-flex justify-content-center pb-5'>
                {serviceData.map((item, index) => (
                    <div className='col-lg-3 mb-5 d-flex justify-content-center'>
                        <div className='row service_card' key={index}>
                            <div className='service_logo d-flex justify-content-center'>
                                {item.logo}
                            </div>
                            <h4 className='service_card_heading'>{item.heading}</h4>
                            <h6 className='text-center service_card_desc'>{item.description}</h6>
                            <button className='service_button'>View More</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Services