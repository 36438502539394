import React, { useEffect, useState } from 'react';
import { HiMenuAlt2 } from "react-icons/hi";
import { IoNotifications } from "react-icons/io5";
import jwt_decode from 'jwt-decode';

const token = localStorage.getItem('token');

const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json', // Set the appropriate content type
};

const UpperBar = () => {
    const [profileData, setProfileData] = useState("");

    const getProfileData = () => {
        try {
            const myData = jwt_decode(localStorage.getItem('token'))
            setProfileData(myData);
        } catch (error) {
            console.log(error);
        };
    };

    useEffect(() => {
        getProfileData();
    }, []);
    return (
        <header>
            <div className='container-fluid fixed-top bg-white navbar_container shadow_header pb-1'>
                <div className='row full_navbar'>
                    <div className='col-2 left_header'>
                        <div className='d-flex align-items-end'>
                            <img src='/Images/ScrapodileLogo.png' className='upperbarLogo me-1' />
                            <span className=''>Scrapodile</span>
                        </div>
                    </div>
                    <div className='col-10 right_header2 pt-1'>
                        <div className='row'>
                            <div className='col-3 right_header_left'>
                                <HiMenuAlt2 />
                            </div>
                            <div className='col-9 right_header_right'>
                                <div className='d-flex justify-content-center fs-4 px-2'>
                                    <IoNotifications />
                                </div>
                                <div className='d-flex justify-content-center align-items-center fs-5'>
                                    <img src='/Images/profiledefault.png' className='profile_picture_upperbar mx-1' />
                                    <h5 className='profile_name_upperbar'>{profileData.name}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default UpperBar